import React, { useState } from "react";
import toggle_menu_icon from "./heroicons-outline_menu-alt-1.png";
import classes from "./ToggleMenu.module.css";
import menuBg from "./menuBg.png";
import { Button, Tooltip } from "@mui/material";
import { AuthClient } from "@dfinity/auth-client";
import { Actor, HttpAgent } from "@dfinity/agent";
import kernelDid from "../../../utils/candid/kernel.did";
import { kernelCanister } from "../../../canisters";
import { useEffect } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { BackButton } from "./../BackButton/BackButton";
import { AClient, principalToAccountIdentifier } from "../../../utils/utils";

export const ToggleMenu = ({ clicked, setClicked, address, setAddress, setConfirmLogOut }) => {
  const [open, setOpen] = useState(false);

  const init = async () => {
    await AClient((id) => {
      setAddress(principalToAccountIdentifier(id.getPrincipal().toText()));
    });
  };

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (!address) {
          clicked ? setClicked(false) : setClicked(true);
        } else {
          setConfirmLogOut(true);
        }
      }}
    >
      <>
        {!address ? (
          <div className={classes.wallet}>
            <AccountBalanceWalletIcon sx={{ color: "#ec832e", fontSize: "40px" }} />
          </div>
        ) : (
          <div className={classes.wallet}>
            <BackButton />
          </div>
        )}

        <div
          className={classes.menu}
          style={clicked ? { opacity: "1", backgroundImage: `url(${menuBg})`, pointerEvents: "all" } : { opacity: "0" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div className={classes.menuButtonsBlock}>
              <Button
                onClick={() => {
                  setClicked(false);
                  init();
                }}
                variant="contained"
              >
                INTERNET IDENTITY
              </Button>
              <Button disabled variant="contained">
                STOIC CONNECT
              </Button>
              <Button disabled variant="contained">
                PLUG CONNECT
              </Button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
