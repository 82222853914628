import React, { useEffect } from "react";
import classes from "./Files.module.css";
import { Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { AuthClient } from "@dfinity/auth-client";
import { Actor, HttpAgent } from "@dfinity/agent";
import { kernelCanister } from "../../../canisters";
import kernelDid from "../../../utils/candid/kernel.did";
import { SearchPanel } from "../../UI/SearchPanel/SearchPanel";
import { getParamOnPage, tokenIdentifier, validateAddress, validatePrincipal } from "../../../utils/utils";
import { TokenItem } from "../../UI/TokenItem/TokenItem";
import loader from "./../../../media/loader.gif";
import { useParams } from "react-router-dom";
import { MyPagination } from "../../Blocks/MyPagination/MyPagination";
import { FileItem } from "../../UI/FileItem/FileItem";
import { weaponsFiles } from "../../../utils/weaponsFiles/weaponsFiles";

export const Files = ({ collection, secondary_color, files }) => {
  let filesElements = [];

  if (weaponsFiles && collection === "weapons") {
    filesElements = weaponsFiles.map((file) => (
      <FileItem
        key={file.name}
        fileName={file.name}
        fileDesc={file.desc}
        fileImg={file.img}
        minIndex={file.minIndex}
        maxIndex={file.maxIndex}
        // supply={nftSupply}
        // setCurrentFile={setCurrentFile}
        secondary_color={secondary_color}
      />
    ));
  } else {
    // filesElements = files;
    if (files) {
      for (let file in files) {
        filesElements.push(
          <FileItem
            key={file}
            fileName={file}
            fileDesc={files[file].length+ " supply"}
            advanced={true}
            collection={collection}
            // minIndex={file.minIndex}
            // maxIndex={file.maxIndex}
            // supply={nftSupply}
            // setCurrentFile={setCurrentFile}
            secondary_color={secondary_color}
          />
        );
      }
    }
  }

  return (
    <>
      {filesElements}

      {/* ) : (
            <>{searchResults}</>
          )
        ) : (
          <div className="loader">
            <img src={loader} alt="loading" />
          </div>
        )} */}
      {/* </Grid> */}
    </>
  );
};
