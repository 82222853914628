import React, { useState } from "react";
import classes from "./Collections.module.css";
import { Grid } from "@mui/material";
import { CollectionItem } from "../../UI/CollectionItem/CollectionItem";
import { SearchPanel } from "../../UI/SearchPanel/SearchPanel";
import { useLocation } from "react-router-dom";
import { getParamOnPage } from "../../../utils/utils";
import { useEffect } from "react";
import decor from "./decor.png";

function compare(a, b) {
  // const categories = ["Dwarves", "Weapons", "Non-grade weapons", "non-grade armor", "Licenses", "Gold"];
  const categories = ["Gold", "Licenses", "non-grade armor", "Non-grade weapons", "Weapons", "Dwarves"];

  const aCategory = categories.indexOf(a[1].name);
  const bCategory = categories.indexOf(b[1].name);

  if (aCategory < bCategory) {
    return 1;
  } else if (aCategory > bCategory) {
    return -1;
  }

  return 0;
}

function separateTypes(array, callback) {
  let nfts = [];
  let tokens = [];
  for (let i in array) {
    if (array[i] && array[i].props.collectionType === "nfts") {
      nfts.push(array[i]);
    } else {
      tokens.push(array[i]);
    }
  }
  callback({ nfts, tokens });
}

export const Collections = ({ collections, secondary_color, primary_color, address }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [nftsElements, setNftsElements] = useState([]);
  const [tokensElements, setTokensElements] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  let id = 0;
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // let searchResults;

  const projectId = getParamOnPage("id");

  let sortedCollections = [];

  if (JSON.parse(collections)) sortedCollections = JSON.parse(collections).sort(compare);

  useEffect(() => {
    if (sortedCollections) {
      const results = !searchTerm
        ? sortedCollections
        : sortedCollections.filter((p) => p[1].name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

      setSearchResults(
        results.map((c) => {
          if (c[1].project == projectId)
            return (
              <CollectionItem
                key={id}
                address={address}
                id={id++}
                name={c[1].name}
                preview={c[1].asset}
                description={c[1].description}
                canister={c[1].ledgerCanister}
                secondary_color={secondary_color}
                curCollection={c[0]}
                collectionType={c[1].collectionType}
              />
            );
          return null;
        })
      );
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchResults.length>0) {
      separateTypes(searchResults, (data) => {
        setNftsElements(data.nfts);
        setTokensElements(data.tokens);
      });
    }
  }, [searchResults]);

  return (
    <>
      <div className="searchPanel">
        <SearchPanel
          title="Enter collection name"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleChange={handleChange}
          color={primary_color}
          icon={true}
        ></SearchPanel>
      </div>
      {searchResults.length !== 0 ? (
        projectId === "0" ? (
          <>
            {nftsElements.length !== 0 && (
              <>
                <div className={classes.root}>
                  <div className={classes.header}>
                    <div className={classes.collectName}>{"NFTs"}</div>
                  </div>
                  <div className={classes.decor}>
                    <img src={decor} alt="decor" />
                  </div>
                </div>
                <Grid justifyContent="left" columns={{ md: 2 }} spacing={3} container>
                  {nftsElements}
                </Grid>
              </>
            )}

            {tokensElements.length !== 0 && (
              <>
                <div style={{ marginTop: 15 }} className={classes.root}>
                  <div className={classes.header}>
                    <div className={classes.collectName}>{"Tokens"}</div>
                  </div>
                  <div className={classes.decor}>
                    <img src={decor} alt="decor" />
                  </div>
                </div>
                <Grid justifyContent="left" columns={{ md: 2 }} spacing={3} container>
                  {tokensElements}
                </Grid>
              </>
            )}
          </>
        ) : (
          searchResults
        )
      ) : (
        <h3 className="emptySearch">It seems there is no such collection</h3>
      )}
    </>
  );
};
