import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import { eimoladCanister, testCanister } from "../canisters";
import eimoladDid from "./candid/eimolad.did";
import miniNftsDid from "./candid/miniNfts.did";
import { tokenIdentifier } from "./utils";
import { folders, nonGradeWeapons } from "./weaponsFiles/weaponsFiles";

export const getTokenInfo = async (collection, tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister,
  });
  const args = {};
  args[collection] = tid;
  await actor.getTokenInfoRare(args).then((data) => {
    callback(data);
  });
  // .then((err) => console.log(err));
};

export const getFolder = async (canister, collectionName, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(miniNftsDid, {
    agent: agent,
    canisterId: canister,
  });

  let result = {};

  for (let item in folders[collectionName]) {
    await actor.getFolder(folders[collectionName][item]).then(async (data) => {
      result[folders[collectionName][item]] = [];
      for (let i in data.ok) {
        let element = { index: 0, tid: "", type: "nfts" };
        element.index = Number(data.ok[i]) + 1;
        element.tid = tokenIdentifier(canister, data.ok[i]);
        result[folders[collectionName][item]].push(element);
      }
    });
  }
  callback(result);
};

export const getItemStateFromTid = async (canister, collectionName, tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(miniNftsDid, {
    agent: agent,
    canisterId: canister,
  });

  await actor
    .getItemStateFromTid(tid) //TODO
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log(err));
};

export const getMetaDataFromRef = async (name, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister,
  });

  await actor.getMetaDataFromRef(name).then((data) => {
    callback(data);
  });
};

export const getCharLvl = async (tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister, //TODO change to eimoladCanister
  });

  await actor.getCharLvl(tid).then((data) => {
    callback(data);
  });
};

export const getWeaponAssetName = async (index, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister, //TODO change to eimoladCanister
  });

  await actor.getWeaponAssetName(index).then((data) => {
    callback(data);
  });
};

export const getAssetName = async (canister, index, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(miniNftsDid, {
    agent: agent,
    canisterId: canister,
  });

  await actor.getMD_asset(index).then((data) => {
    callback(data);
  });
};
