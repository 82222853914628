import ancient_axe from "./ancient_axe.png";
import doom_hammer from "./doom_hammer.png";
import mace_of_rage from "./mace_of_rage.png";
import мace_of_a_wandering_magician from "./wandering.png";
import legionnaires_sword from "./legionnaires_sword.png";
import knights_sword from "./knights_sword.png";
import assassins_sword from "./assassins_sword.png";
import viking_axe from "./viking_axe.png";
import guardsmans_axe from "./guardsmans_axe.png";
import axe_of_valor from "./axe_of_valor.png";
import mystic_hammer from "./mystic_hammer.png";
import armor_piercing_pickax from "./armor_piercing_pickax.png";
import holy_halberd from "./holy_halberd.png";
import mercenarys_sword from "./mercenarys_sword.png";
import dukes_sword from "./dukes_sword.png";
import the_punisher_sword from "./the_punisher_sword.png";
import test from "./Equipment.png";

export const weaponsFiles = [
  { name: "Ancient axe", desc: "140 supply", minIndex: 0, maxIndex: 140, img: ancient_axe },
  { name: "Doom hammer", desc: "200 supply", minIndex: 140, maxIndex: 340, img: doom_hammer },
  { name: "Mace of Rage", desc: "250 supply", minIndex: 340, maxIndex: 590, img: mace_of_rage },
  { name: "Mace of a Wandering", desc: "25 supply", minIndex: 590, maxIndex: 615, img: мace_of_a_wandering_magician },
  { name: "Legionnaires sword", desc: "150 supply", minIndex: 615, maxIndex: 765, img: legionnaires_sword },
  { name: "Knights sword", desc: "300 supply", minIndex: 765, maxIndex: 1065, img: knights_sword },
  { name: "Assassins sword", desc: "60 supply", minIndex: 1065, maxIndex: 1125, img: assassins_sword },
  { name: "Viking axe", desc: "100 supply", minIndex: 1125, maxIndex: 1225, img: viking_axe },
  { name: "Guardsmans axe", desc: "300 supply", minIndex: 1225, maxIndex: 1525, img: guardsmans_axe },
  { name: "Axe of Valor", desc: "150 supply", minIndex: 1525, maxIndex: 1675, img: axe_of_valor },
  { name: "Mystic Hammer", desc: "25 supply", minIndex: 1675, maxIndex: 1700, img: mystic_hammer },
  { name: "Armor Piercing Pickax", desc: "250 supply", minIndex: 1700, maxIndex: 1950, img: armor_piercing_pickax },
  { name: "Holy halberd", desc: "350 supply", minIndex: 1950, maxIndex: 2300, img: holy_halberd },
  { name: "Mercenarys sword", desc: "200 supply", minIndex: 2300, maxIndex: 2500, img: mercenarys_sword },
  { name: "Dukes sword", desc: "320 supply", minIndex: 2500, maxIndex: 2820, img: dukes_sword },
  { name: "The Punisher sword", desc: "60 supply", minIndex: 2820, maxIndex: 2880, img: the_punisher_sword },
];

export const testPic = test;

export const nonGradeWeapons = ["simple_baton", "iron_baton", "simple_dagger", "sadist", "simple_staff", "shaman_staff", "simple_bow", "lorean_bow"];

export const folders = {
  ["Non-grade weapons"]: ["simple_baton", "iron_baton", "simple_dagger", "sadist", "simple_staff", "shaman_staff", "simple_bow", "lorean_bow"],
  ["non-grade armor"]: [
    "Armor_of_Royal_Valor",
    "Gloves_of_Royal_Valor",
    "Boots_of_Royal_Valor",
    "Armor_of_The_Damned",
    "Gloves_of_The_Damned",
    "Boots_of_The_Damned",
    "Armor_of_Mournful_Cry",
    "Gloves_of_Mournful_Cry",
    "Boots_of_Mournful_Cry",
    "Rogues_Leather_Armor",
    "Rogues_Leather_Gloves",
    "Rogues_Leather_Boots",
    "Reliable_Barrel_Armor",
    "Reliable_Barrel_Gloves",
    "Reliable_Barrel_Boots",
    "Robe_of_The_Holy_Seal",
    "Gloves_of_The_Holy_Seal",
    "Boots_of_The_Holy_Seal",
    "Tiara_of_the_Holy_Seal",
    "Warriors_Shield",
    "Grenlyns_Shield",
    "Dwarven_Heavy_Helmet",
    "Leather_Helmet",
  ],
  ["Licenses"]: [
    "l_simple_baton",
    "l_iron_baton",
    "l_simple_dagger",
    "l_sadist",
    "l_simple_staff",
    "l_shaman_staff",
    "l_simple_bow",
    "l_lorean_bow",
    "l_Armor_of_Royal_Valor",
    "l_Gloves_of_Royal_Valor",
    "l_Boots_of_Royal_Valor",
    "l_Armor_of_The_Damned",
    "l_Gloves_of_The_Damned",
    "l_Boots_of_The_Damned",
    "l_Armor_of_Mournful_Cry",
    "l_Gloves_of_Mournful_Cry",
    "l_Boots_of_Mournful_Cry",
    "l_Rogues_Leather_Armor",
    "l_Rogues_Leather_Gloves",
    "l_Rogues_Leather_Boots",
    "l_Reliable_Barrel_Armor",
    "l_Reliable_Barrel_Gloves",
    "l_Reliable_Barrel_Boots",
    "l_Robe_of_The_Holy_Seal",
    "l_Gloves_of_The_Holy_Seal",
    "l_Boots_of_The_Holy_Seal",
    "l_Tiara_of_the_Holy_Seal",
    "l_Warriors_Shield",
    "l_Grenlyns_Shield",
    "l_Dwarven_Heavy_Helmet",
    "l_Leather_Helmet",
  ],
};
