import { Alert, Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import "./App.css";
import React, { useEffect, useState } from "react";
import { MenuBar } from "./components/Blocks/MenuBar/MenuBar";
import { TokenInfo } from "./components/Pages/TokenInfo/TokenInfo";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Index } from "./components/Pages/Index/Index";
import { Collections } from "./components/Pages/Collections/Collections";
import { ProjectCreationForm } from "./components/Pages/ProjectCreationForm/ProjectCreationForm";
import { CollectionCreationForm } from "./components/Pages/CollectionCreationForm/CollectionCreationForm";
import { AuthClient } from "@dfinity/auth-client";
import { Actor, HttpAgent } from "@dfinity/agent";
import { kernelCanister } from "./canisters";
import kernelDid from "./utils/candid/kernel.did";
import loader from "./media/loader.gif";
import { Tokens } from "./components/Pages/Tokens/Tokens";
import copy from "./media/copy.png";

import {
  AClient,
  clipboardCopy,
  fromHexString,
  getAddress,
  getParamOnPage,
  getPrincipal,
  getSubAccountArray,
  principalToAccountIdentifier,
  validateAddress,
} from "./utils/utils";
import { SearchPanel } from "./components/UI/SearchPanel/SearchPanel";
import { TransactionsTable } from "./components/UI/TransactionsTable/TransactionsTable";
import { Transactions } from "./components/Pages/Transactions/Transactions";
import CloseIcon from "@mui/icons-material/Close";
import { ModalWindow } from "./components/Blocks/ModalWindow/ModalWindow";
import { Principal } from "@dfinity/agent/lib/cjs/idl";
import { CollectionUpdateForm } from "./components/Pages/CollectionUpdateForm/CollectionUpdateForm";
import { findTransactionsFromAid, getAllCollections, getAllProjects, getCollectionsAssets } from "./utils/ictokensUtils";

const PRIMARY_COLOR = "rgba(236, 131, 46, 1)";
const SECONDARY_COLOR = "rgba(59,59,59,0.5)";

const logOut = () => {
  localStorage.clear();
  window.location.replace("/");
};

function App() {
  const [copied, setCopied] = useState(false);
  const [projects, setProjects] = useState(null);
  const [collections, setCollections] = useState(null);
  const [clickedMenu, setClickedMenu] = useState(false);
  const [address, setAddress] = useState(null);
  const [collectionCanisters, setCollectionCanisters] = useState([]);

  const [searchTermMenu, setSearchTermMenu] = useState("");
  const [transactionsMenu, setTransactionsMenu] = useState(null);
  const [transactionsFound, setTransactionsFound] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [confirmLogOut, setConfirmLogOut] = useState(false);

  // useEffect(()=>{
  //   getCollectionsAssets((data)=>{console.log(data)})
  // },[])

  let searchResults;

  let type = getParamOnPage("type");

  if (
    localStorage.getItem("ic-delegation") &&
    !address &&
    localStorage.getItem("ic-delegation") !== "" &&
    localStorage.getItem("ic-identity") !== ""
  ) {
    getAddress((addr) => setAddress(addr));
  }
  let navigate = useNavigate();

  async function handleSubmit(address) {
    navigate(address);
  }

  if (projects === null && collections === null) {
    getAllProjects((data) => {
      setProjects(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
    });

    let array = [];

    getAllCollections((data) => {
      setCollections(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
      data.map((c) => {
        array.push(c[1].ledgerCanister);
      });

      let array2 = array.filter((item, index) => {
        return array.indexOf(item) == index;
      });
      setCollectionCanisters(array2);
    });
  }

  const handleChange = (event) => {
    setSearchTermMenu(event.target.value);
  };

  useEffect(() => {
    if (transactionsMenu !== null) {
      setTransactionsFound(true);
    }
  }, [transactionsMenu]);

  useEffect(() => {
    setTransactionsFound(false);
    setTransactionsMenu(null);
  }, [searchTermMenu]);

  if (validateAddress(searchTermMenu)) {
    if (transactionsFound === false) {
      findTransactionsFromAid(searchTermMenu, (data) => {
        setTransactionsMenu(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
        setTransactionsFound(true);
      });
    }

    searchResults = [
      <div style={{ paddingLeft: "14px" }}>
        {transactionsMenu ? (
          <TransactionsTable
            setCopied={setOpenAlert}
            copied={openAlert}
            key={0}
            page={"index"}
            transactions={transactionsMenu}
            primary_color={PRIMARY_COLOR}
            secondary_color={SECONDARY_COLOR}
          />
        ) : (
          <div className={"loaderContainer"}>
            <div className={"loader"}>
              <img src={loader} alt="loading" />
            </div>
          </div>
        )}
      </div>,
    ];
  }


  return (
    <div
      onClick={() => {
        if (clickedMenu) {
          setClickedMenu(false);
        }
      }}
      className="App"
    >
      <ModalWindow active={confirmLogOut} setActive={setConfirmLogOut}>
        <Typography sx={{ textTransform: "none" }}>Are you sure you want to exit the wallet?</Typography>
        <div className="modalButtons">
          <Button variant="contained" onClick={logOut}>
            Yes
          </Button>
          <Button variant="contained" onClick={() => setConfirmLogOut(false)}>
            No
          </Button>
        </div>
      </ModalWindow>
      <MenuBar address={address} setAddress={setAddress} setConfirmLogOut={setConfirmLogOut} clicked={clickedMenu} setClicked={setClickedMenu}>
        <div className="menuBarContent">
          <h2 onClick={() => handleSubmit("/")}>ICtokens</h2>
          <div className="searchInMenuBar">
            <SearchPanel
              title="Enter your address"
              searchTerm={searchTermMenu}
              setSearchTerm={setSearchTermMenu}
              handleChange={handleChange}
              color={PRIMARY_COLOR}
            />
          </div>
          <div className="menuBarContent__buttons">
            {address ? (
              <div
                className="address"
                onClick={() => {
                  clipboardCopy(address);
                  setCopied(true);
                  setTimeout(() => setCopied(false), 1000);
                }}
              >
                {copied ? <div>Copied</div> : null}
                <img src={copy} alt="Copy address" />
                {address.substr(0, 6) + "..." + address.substr(58, 64)}
              </div>
            ) : null}
            {collections && address === "f335c96f18eb2c5f727ce2732795f7b42e1c21495f9200f0f535bb04f2fd4c74" || address === "eaad75456f94afc96a9d5fce7c5685134901b5bbcdb80816c52521564b019380" ? (
              // address==='4da391c2f5f27d00c20dca85f4ccd2002b7e9a93940171cb622d535e17a59203'
              <Routes>
                <Route
                  path="/"
                  element={
                    <Button
                      onClick={() => {
                        handleSubmit("project_creation");
                      }}
                      sx={{ height: "30px", fontSize: "10px" }}
                      variant="contained"
                    >
                      Add project
                    </Button>
                  }
                />
                <Route
                  path="/:name"
                  element={
                    <Button
                      onClick={() => {
                        handleSubmit("collection_creation");
                      }}
                      style={{ marginLeft: "10px" }}
                      sx={{ height: "30px", fontSize: "10px" }}
                      variant="contained"
                    >
                      Add collection
                    </Button>
                  }
                />
              </Routes>
            ) : null}
          </div>
        </div>
      </MenuBar>
      <div className="main">
        <div className="content">
          {collections ? (
            <Routes>
              {searchResults ? (
                <Route
                  path="/"
                  element={
                    <Transactions
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      transactions={transactionsMenu}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                    />
                  }
                />
              ) : (
                <Route
                  path="/"
                  element={
                    <Index
                      collectionCanisters={collectionCanisters}
                      projects={projects}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                      address={address}
                      setSearchTermMenu={setSearchTermMenu}
                    />
                  }
                />
              )}
              {searchResults ? (
                <Route
                  path="/:projectName"
                  element={
                    <Transactions
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      transactions={transactionsMenu}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                    />
                  }
                />
              ) : (
                <Route
                  path="/:projectName"
                  element={
                    <Collections
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      collections={collections}
                      secondary_color={SECONDARY_COLOR}
                      primary_color={PRIMARY_COLOR}
                      address={address}
                    />
                  }
                />
              )}

              {searchResults ? (
                <Route
                  path="/:projectName"
                  element={
                    <Transactions
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      transactions={transactionsMenu}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                    />
                  }
                />
              ) : (
                <Route
                  path="/:projectName"
                  element={
                    <Collections collections={collections} secondary_color={SECONDARY_COLOR} primary_color={PRIMARY_COLOR} address={address} />
                  }
                />
              )}

              {type === "nfts" ? (
                searchResults ? (
                  <Route
                    path="/:projectName/:collectionName"
                    element={
                      <Transactions
                        setCopied={setOpenAlert}
                        copied={openAlert}
                        transactions={transactionsMenu}
                        primary_color={PRIMARY_COLOR}
                        secondary_color={SECONDARY_COLOR}
                      />
                    }
                  />
                ) : (
                  <Route
                    path="/:projectName/:collectionName"
                    element={<Tokens address={address} primary_color={PRIMARY_COLOR} secondary_color={SECONDARY_COLOR} />}
                  />
                )
              ) : searchResults ? (
                <Route
                  path="/:projectName/:collectionName"
                  element={
                    <Transactions
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      transactions={transactionsMenu}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                    />
                  }
                />
              ) : (
                <Route
                  path="/:projectName/:collectionName"
                  element={<TokenInfo setCopied={setOpenAlert} copied={openAlert} primary_color={PRIMARY_COLOR} secondary_color={SECONDARY_COLOR} />}
                />
              )}

              {searchResults ? (
                <Route
                  path="/:projectName/:collectionName/:tid"
                  element={
                    <Transactions
                      setCopied={setOpenAlert}
                      copied={openAlert}
                      transactions={transactionsMenu}
                      primary_color={PRIMARY_COLOR}
                      secondary_color={SECONDARY_COLOR}
                    />
                  }
                />
              ) : (
                <Route
                  path="/:projectName/:collectionName/:tid"
                  element={<TokenInfo setCopied={setOpenAlert} copied={openAlert} primary_color={PRIMARY_COLOR} secondary_color={SECONDARY_COLOR} />}
                />
              )}

              <Route path="/project_creation" element={<ProjectCreationForm secondary_color={SECONDARY_COLOR} />} />
              <Route path="/collection_creation" element={<CollectionCreationForm projects={projects} secondary_color={SECONDARY_COLOR} />} />
              <Route path="/collection_update" element={<CollectionUpdateForm projects={projects} secondary_color={SECONDARY_COLOR} />} />

              <Route path="*" element={<h2>The resource can not be found</h2>} />
            </Routes>
          ) : (
            <div className="loader">
              <img src={loader} alt="loading" />
            </div>
          )}
        </div>
      </div>
      <div className="alert">
        <Collapse in={openAlert}>
          <Alert
            color="primary"
            action={
              <IconButton
                aria-label="close"
                color="primary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, backgroundColor: "rgba(59,59,59,1)" }}
          >
            Copied!
          </Alert>
        </Collapse>
      </div>
    </div>
  );
}

export default App;
