import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import { canisters, eimoladCanister, kernelCanister, testCanister } from "../canisters";
import eimoladDid from "./candid/eimolad.did";
import kernelDid from "./candid/kernel.did";
import miniNftsDid from "./candid/miniNfts.did";

export const getAllProjects = async (callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  actor.showProjectList().then((data) => {
    callback(data);
  });
};

export const getAllCollections = async (callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  actor.showCollectionList().then((data) => {
    callback(data);
  });
};

export const findTransactionsFromAid = async (aid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  const actor2 = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  let transactionsArray = [];

  await actor.getCanistersArray().then((data) => {
    let i = 0;
    data.map((c) => {
      actor2.findTransactionsFromAid(c, aid).then((res) => {
        res.map((t) => {
          t.canister = c;
        });
        transactionsArray = transactionsArray.concat(res);
        i++;
        if (data.length === i) {
          callback(transactionsArray);
        }
      });
    });
  });
};

export const findTransactionsFromTID = async (principalCanister, tid, callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  actor.findTransactionsFromTID(principalCanister, tid).then((data) => {
    callback(data);
  });
};

export const getTransactions = async (canister, callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  actor.getTransactions(canister).then((data) => {
    callback(data);
  });
};

export const getICRCTransactions = async (canister, curPage, rowsPerPage, callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  actor.getICRCTransactions(canister, curPage, rowsPerPage).then((data) => {
    callback(data);
  });
};

export const getCirculateTokens = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor.getCirculateTokens(canister).then((data) => {
    callback(data);
  });
};

export const getTotalSupply = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor
    .getTotalSupply(canister)
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log(err));
};

export const getTokenMetaData = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor
    .getTokenMetaData(canister)
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log(err));
};

export const getAttributes = async (tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor.getAttributes(eimoladCanister, tid).then((data) => {
    callback(data);
  });
  // .then((err) => console.log(err));
};

export const getWeaponAttributes = async (tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister,
  });

  await actor.getMd(tid).then((data) => {
    callback([data]);
  });
  // .then((err) => console.log(err));
};

export const getCollectionsAssets = async (callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor
    .getCollectionsAssets()
    .then((data) => {
      callback(data);
    })
    .then((err) => console.log(err));
};

let loadFile = async (bfile) => {
  let arrOfS = [];
  let i = 0;
  while (i < bfile.length) {
    arrOfS.push(bfile[i]);
    i++;
  }
  return arrOfS;
};

export const updateAsset = async (fileName, droppedFile, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  let addAsset = async (fileName, droppedFile) => {
    const payload_file = await loadFile(droppedFile);
    const payload = { data: [payload_file], ctype: "image/jpg" };
    const Asset = { name: fileName, payload: payload };
    return Asset;
  };

  addAsset(fileName, droppedFile).then((data) => {
    actor.updateAsset(fileName, data).then((res) => {
      callback(res);
    });
  });
};

export const getMiniNftsAttributes = async (canister, tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(miniNftsDid, {
    agent: agent,
    canisterId: canister,
  });

  await actor.getItemMd(tid).then((data) => {
    callback(data);
  });
  // .then((err) => console.log(err));
};

export const getMDFromRef = async (canister, tid, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(eimoladDid, {
    agent: agent,
    canisterId: eimoladCanister,
  });

  await actor.getMDFromRef(canister, tid).then((data) => {
    callback(data);
  });
  // .then((err) => console.log(err));
};

export const getTokenDetails = async (tid, canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(miniNftsDid, {
    agent: agent,
    canisterId: canister,
  });

  await actor.details(tid).then((data) => {
    callback(data);
  });
  // .then((err) => console.log(err));
};
