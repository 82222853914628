import { Button, MenuItem, Paper, Select, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { DragAndDrop } from "../../UI/DragAndDrop/DragAndDrop";
import classes from "./CollectionUpdateForm.module.css";
import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import kernelDid from "../../../utils/candid/kernel.did";
import { kernelCanister } from "../../../canisters";

const updateCollection = async (collectionID, titleValue, name, descriptionValue, file_name, canisterValue, projectId, typeValue, callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });
  let args = {
    asset: 0,
    name: titleValue,
    description: descriptionValue,
    ledgerCanister: canisterValue,
    project: Number(projectId),
    collectionType: typeValue,
  };
  console.log(collectionID, args);

  actor.updateCollection(Number(collectionID), args).then((data) => {
    callback(data);
  });
};

export const CollectionUpdateForm = ({ projects, secondary_color }) => {
  const [projectValue, setProjectValue] = useState("0");
  const [typeValue, setTypeValue] = useState("nfts");
  const [titleValue, setTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [dropedFile, setDropedFile] = useState(null);
  const [dropedFileName, setDropedFileName] = useState("");
  const [canisterValue, setCanisterValue] = useState("");
  const [collectionID, setCollectionID] = useState(0);

  let projectsItems = [];
  let typesItems = [
    <MenuItem key={0} value={"nfts"}>
      {" "}
      {"nfts"}
    </MenuItem>,
    <MenuItem key={1} value={"token"}>
      {" "}
      {"token"}
    </MenuItem>,
    <MenuItem key={2} value={"icrcToken"}>
      {" "}
      {"icrcToken"}
    </MenuItem>,
  ];

  if (projects) {
    JSON.parse(projects).map((p) => {
      projectsItems.push(
        <MenuItem key={p[0]} value={p[0]}>
          {p[1].title}
        </MenuItem>
      );
    });
  }

  console.log(projectValue, typeValue, titleValue, descriptionValue, canisterValue, dropedFile, dropedFileName);

  return (
    <Paper style={{ width: "100%", height: "100%", backgroundColor: secondary_color, color: "#c7c7c7" }}>
      <div className={classes.form}>
        <h2>Add collection</h2>

        <TextField
          id="outlined-basic"
          onChange={(e) => setCollectionID(e.target.value)}
          label="Collection ID"
          variant="outlined"
          value={collectionID}
          style={{
            padding: "0",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          style={{ color: "white", backgroundColor: secondary_color, width: "210px", marginBottom: "15px", marginTop: "15px" }}
          value={projectValue}
          onChange={(e) => {
            setProjectValue(e.target.value);
          }}
          label="Project"
        >
          {projectsItems}
        </Select>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          style={{ color: "white", backgroundColor: secondary_color, width: "210px", marginBottom: "15px" }}
          value={typeValue}
          onChange={(e) => {
            setTypeValue(e.target.value);
          }}
          label="Type canister"
        >
          {typesItems}
        </Select>

        <TextField
          id="outlined-basic"
          onChange={(e) => setTitleValue(e.target.value)}
          label="Title"
          variant="outlined"
          value={titleValue}
          style={{
            padding: "0",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <TextField
          id="outlined-basic"
          onChange={(e) => setDescriptionValue(e.target.value)}
          multiline
          label="Description"
          variant="outlined"
          value={descriptionValue}
          style={{
            padding: "0",
            marginTop: "15px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "30px 20px 30px 20px",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <TextField
          id="outlined-basic"
          onChange={(e) => setCanisterValue(e.target.value)}
          label="Canister ID"
          variant="outlined"
          value={canisterValue}
          style={{
            padding: "0",
            marginTop: "15px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <Button
          style={{ marginTop: "15px" }}
          onClick={() => {
            updateCollection(
              collectionID,
              titleValue,
              dropedFileName,
              descriptionValue,
              dropedFile,
              canisterValue,
              projectValue,
              typeValue,
              (data) => {
                console.log(data);
              }
            );
          }}
          sx={{ height: "40px" }}
          variant="contained"
        >
          Update collection
        </Button>
      </div>
    </Paper>
  );
};
