import React from "react";
import classes from "./MyPagination.module.css";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "space-around",
});

const List__item = styled("li")({
  width: "100%",
});

export const MyPagination = ({ totalItems, itemsPerPage, paginate, primary_color }) => {
  let numbers = Math.ceil(totalItems / itemsPerPage);
  const { items } = usePagination({
    count: numbers,
  });
  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <a
                className={selected ? classes.animatedButtonSelect + " " + classes.animatedButton : classes.animatedButton}
                {...item}
                key={item}
              >
                {page}
              </a>
            );
          } 
          return (
            <List__item>
              <li
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  if (type === "page" && Math.sign(page) === 1) paginate(page);
                  if (type === "previous" && Math.sign(page) === 1) {
                    paginate(page, true, false);
                  }
                  if (type === "next" && page !== totalItems + 1) {
                    paginate(page, false, true);
                  }
                }}
                key={index}
              >
                {children}
              </li>
            </List__item>
          );
        })}
      </List>
    </nav>
  );
};
