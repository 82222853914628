import { Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TokenInfo } from "../../Pages/TokenInfo/TokenInfo";
import classes from "./TokenItem.module.css";
import cornerRight from "./../../../media/cornerDecorRight.png";
import cornerLeft from "./../../../media/cornerDecorLeft.png";

export const TokenItem = ({ id, index, tid, ledgerCanister, secondary_color, titleToken, totalSupplyToken, type }) => {
  let navigate = useNavigate();
  let name;
  if (tid) {
    name = tid;
  } else {
    name = titleToken;
  }

  async function handleSubmitCollections(tid, index, ledgerCanister, type) {
    navigate(tid + "?index=" + index + "&?canister=" + ledgerCanister + "&?type=" + type);
  }
  let token = {};
  if (tid) {
    token = {
      index: index,
      tid: tid,
    };
  } else {
    token = {
      title: titleToken,
      totalSupply: totalSupplyToken,
      ledgerCanister: ledgerCanister,
    };
  }

  if (type === "nfts") {
    return (
      <Grid item>
        <div className="gridItem">
          <img className={"gridItem__cornerDecorLeft"} src={cornerLeft} alt="Corner Decor" />
          <img className={"gridItem__cornerDecorRight"} src={cornerRight} alt="Corner Decor" />
          <Card
            onClick={() => {
              if (type === "nfts") {
                handleSubmitCollections(name, index, ledgerCanister, type);
              }
            }}
            style={{ backgroundColor: secondary_color }}
            key={index}
            className="card"
          >
            <CardActionArea>
              <div className={classes.imgContainer}>
                <img src={"https://" + ledgerCanister + ".raw.ic0.app/?type=thumbnail&tokenid=" + tid} alt="img" />
              </div>
              <CardContent sx={{ paddingTop: 0 }}>
                <Typography
                  component={"h2"}
                  style={{ paddingLeft: "13px", fontSize: "24px", color: "rgba(236, 131, 46, 1)", textTransform: "none" }}
                  gutterBottom
                >
                  #{index}
                </Typography>
                <Typography component={"p"} style={{ textTransform: "none" }} fontSize={15}>
                  TID: {tid}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Grid>
    );
  } else {
    return (
      <Paper style={{ width: "100%", height: "100%", backgroundColor: secondary_color, color: "#c7c7c7", marginTop: "15px" }}>
        <TokenInfo primary_color={"rgba(236, 131, 46, 1)"} secondary_color={secondary_color} />
      </Paper>
    );
  }
};
