import { Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TokenInfo } from "../../Pages/TokenInfo/TokenInfo";
import classes from "./FileItem.module.css";
import cornerRight from "./../../../media/cornerDecorRight.png";
import cornerLeft from "./../../../media/cornerDecorLeft.png";
import { getParamOnPage } from "../../../utils/utils";

function fixLicense(fileName) {
  let words = fileName.split("_");
  let result = [];

  for (let word of words) {
    result.push(word[0].toUpperCase() + word.slice(1));
  }

  return "License of " + result.join(" ").slice(1);
}

export const FileItem = ({ fileName, fileDesc, fileImg, minIndex, maxIndex, supply, setCurrentFile, secondary_color, advanced, collection }) => {
  let navigate = useNavigate();

  const { collectionName } = useParams();

  let collectionId = getParamOnPage("id");

  let ledgerCanister = getParamOnPage("canister");

  let type = getParamOnPage("type");

  let file = getParamOnPage("file");

  async function handleSubmitWeapons(id, canister, collectionType, fileName) {
    navigate(
      "?id=" + id + "&?canister=" + canister + "&?type=" + collectionType + "&?file=" + fileName + "&?minIndex=" + minIndex + "&?maxIndex=" + maxIndex
    );
  }

  async function handleSubmit(id, canister, collectionType, fileName) {
    navigate("?id=" + id + "&?canister=" + canister + "&?type=" + collectionType + "&?file=" + fileName);
  }

  return (
    <Grid item>
      <div className="gridItem">
        <img className={"gridItem__cornerDecorLeft"} src={cornerLeft} alt="Corner Decor" />
        <img className={"gridItem__cornerDecorRight"} src={cornerRight} alt="Corner Decor" />
        <Card
          onClick={() => {
            if (collectionName === "Weapons") {
              handleSubmitWeapons(collectionId, ledgerCanister, type, fileName);
            } else {
              handleSubmit(collectionId, ledgerCanister, type, fileName);
            }
          }}
          style={{ backgroundColor: secondary_color }}
          // key={index}
          className="card"
        >
          <CardActionArea>
            <div className={classes.imgContainer}>
              <img src={advanced ? "https://" + ledgerCanister + ".raw.ic0.app/?asset=" + fileName + "_thumbnail" : fileImg} alt="img" />
            </div>
            <CardContent sx={{ paddingTop: 0 }}>
              <Typography
                component={"h2"}
                style={
                  window.innerWidth < 441
                    ? { paddingLeft: "13px", fontSize: "17px", color: "rgba(236, 131, 46, 1)", textTransform: "none" }
                    : { paddingLeft: "13px", fontSize: "20px", color: "rgba(236, 131, 46, 1)", textTransform: "none" }
                }
                gutterBottom
              >
                {collection === "Licenses" ? fixLicense(fileName) : fileName[0].toUpperCase() + fileName.slice(1).replace(/_/g, " ")}
              </Typography>
              {window.innerWidth < 441 ? null : (
                <Typography component={"p"} style={{ textTransform: "none" }} fontSize={15}>
                  {fileDesc}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
};
