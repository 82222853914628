import React, { useState } from "react";
import { clipboardCopy } from "../../../utils/utils";
import classes from "./ParamItem.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Fade } from "@mui/material";

export const ParamItem = ({ name, value, setCopied, copied, advanced, metadata }) => {
  const [clicked, setClicked] = useState(false);
  let metadataElements = [];
  if (metadata && advanced) {
    for (let key in metadata) {
      // console.log(key);
      metadataElements.push(
        <div className={classes.metadata__item} key={key}>
          <p>{key}</p>
          <b>{metadata[key]}</b>
        </div>
      );
    }
  }

  return (
    <div
      onClick={() => {
        if (advanced) {
          clicked ? setClicked(false) : setClicked(true);
        }
      }}
      style={{ outline: clicked ? "1px solid #ec832e" : "none", cursor: advanced ? "pointer" : "auto" }}
      className={classes.root}
    >
      <div className={classes.content}>
        <p>{name}</p>
        <b
          style={(typeof value === "string" && value.length === 64) || value.length === 43 ? { cursor: "pointer" } : null}
          onClick={() => {
            if ((typeof value === "string" && value.length === 64) || value.length === 43) {
              clipboardCopy(value);
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }
          }}
        >
          {typeof value === "string" && value.length === 64
            ? value.substr(0, 6) + "..." + value.substr(58, 64)
            : value.length === 43
            ? value.substr(0, 6) + "..." + value.substr(37, 43)
            : value}
        </b>
      </div>

      {advanced ? (
        clicked ? (
          <div className={classes.downImg}>
            <ArrowDropUpIcon />
          </div>
        ) : (
          <div className={classes.downImg}>
            <ArrowDropDownIcon />
          </div>
        )
      ) : null}
      {metadata ? (
        <Fade timeout={800} in={clicked}>
          <div style={{ visibility: clicked ? "visible" : "hidden" }} className={classes.metadata}>
            {metadataElements}
          </div>
        </Fade>
      ) : null}
    </div>
  );
};
