import { Button, MenuItem, Paper, Select, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { DragAndDrop } from "../../UI/DragAndDrop/DragAndDrop";
import classes from "./CollectionCreationForm.module.css";
import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import kernelDid from "./../../../utils/candid/kernel.did";
import { kernelCanister } from "../../../canisters";
import { Link } from "react-router-dom";
import { getCollectionsAssets, updateAsset } from "../../../utils/ictokensUtils";

const addCollections = async (titleValue, name, descriptionValue, file_name, canisterValue, projectId, typeValue, callback) => {
  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });
  console.log(titleValue, name, descriptionValue, file_name, canisterValue, projectId, typeValue);
  let args = {
    asset: 0,
    name: titleValue,
    description: descriptionValue,
    ledgerCanister: canisterValue,
    project: projectId,
    collectionType: typeValue,
  };

  let addAsset = async (name, file_name) => {
    const payload_file = await loadFile(file_name);
    const payload = { data: [payload_file], ctype: "image/jpg" };
    const Asset = { name: name, payload: payload };
    return Asset;
  };

  let asset = addAsset(name, file_name).then((data) => {
    actor.addCollection(args, data).then((data) => {
      callback(data);
    });
  });
};

let loadFile = async (bfile) => {
  let arrOfS = [];
  let i = 0;
  while (i < bfile.length) {
    arrOfS.push(bfile[i]);
    i++;
  }
  return arrOfS;
};

export const CollectionCreationForm = ({ projects, secondary_color }) => {
  const [projectValue, setProjectValue] = useState("0");
  const [typeValue, setTypeValue] = useState("nfts");
  const [titleValue, setTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [dropedFile, setDropedFile] = useState(null);
  const [dropedFileName, setDropedFileName] = useState("");
  const [canisterValue, setCanisterValue] = useState("");

  const [assetName, setAssetName] = useState("");

  let projectsItems = [];
  let typesItems = [
    <MenuItem key={0} value={"nfts"}>
      {" "}
      {"nfts"}
    </MenuItem>,
    <MenuItem key={1} value={"token"}>
      {" "}
      {"token"}
    </MenuItem>,
    <MenuItem key={2} value={"icrcToken"}>
      {" "}
      {"icrcToken"}
    </MenuItem>,
  ];

  if (projects) {
    JSON.parse(projects).map((p) => {
      projectsItems.push(
        <MenuItem key={p[0]} value={p[0]}>
          {p[1].title}
        </MenuItem>
      );
    });
  }

  function onDropHandler(e) {
    e.preventDefault();
    let files = [...e.dataTransfer.files];
    var reader = new FileReader();
    reader.onload = function (e) {
      var arrayBuffer = reader.result;
      var view = new Uint8Array(arrayBuffer);
      setDropedFile(view);
      setDropedFileName(files[0].name);
    };
    reader.readAsArrayBuffer(files[0]);
  }

  console.log(dropedFile, dropedFileName);

  return (
    <Paper style={{ width: "100%", height: "100%", backgroundColor: secondary_color, color: "#c7c7c7" }}>
      <div className={classes.form}>
        <h2>Add collection</h2>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          style={{ color: "white", backgroundColor: secondary_color, width: "210px", marginBottom: "15px" }}
          value={projectValue}
          onChange={(e) => {
            setProjectValue(e.target.value);
          }}
          label="Project"
        >
          {projectsItems}
        </Select>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          style={{ color: "white", backgroundColor: secondary_color, width: "210px", marginBottom: "15px" }}
          value={typeValue}
          onChange={(e) => {
            setTypeValue(e.target.value);
          }}
          label="Type canister"
        >
          {typesItems}
        </Select>

        <TextField
          id="outlined-basic"
          onChange={(e) => setTitleValue(e.target.value)}
          label="Title"
          variant="outlined"
          value={titleValue}
          style={{
            padding: "0",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <TextField
          id="outlined-basic"
          onChange={(e) => setDescriptionValue(e.target.value)}
          multiline
          label="Description"
          variant="outlined"
          value={descriptionValue}
          style={{
            padding: "0",
            marginTop: "15px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "30px 20px 30px 20px",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <TextField
          id="outlined-basic"
          onChange={(e) => setCanisterValue(e.target.value)}
          label="Canister ID"
          variant="outlined"
          value={canisterValue}
          style={{
            padding: "0",
            marginTop: "15px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <DragAndDrop onDropHandler={onDropHandler} />

        <Button
          style={{ marginTop: "15px" }}
          onClick={() => {
            addCollections(titleValue, dropedFileName, descriptionValue, dropedFile, canisterValue, Number(projectValue), typeValue, (data) => {
              console.log(data);
            });
          }}
          sx={{ height: "40px" }}
          variant="contained"
        >
          Add collection
        </Button>
        <Link to={"/collection_update"}>
          <Button style={{ marginTop: "45px" }} onClick={() => {}} sx={{ height: "40px" }} variant="contained">
            Update collection
          </Button>
        </Link>
        <TextField
          id="outlined-basic"
          onChange={(e) => setAssetName(e.target.value)}
          label="Asset name"
          variant="outlined"
          value={assetName}
          style={{
            padding: "0",
            marginTop: "45px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />
        <button onClick={()=>{
          getCollectionsAssets((data)=>{console.log(data)})
        }}>check assets</button>
        <Button
          style={{ marginTop: "25px" }}
          onClick={() => {
            updateAsset(assetName, dropedFile, (data) => {
              console.log(data);
            });
          }}
          sx={{ height: "40px" }}
          variant="contained"
        >
          Update asset
        </Button>
      </div>
    </Paper>
  );
};
