export const dwarfBaseAttributes = {
  strength: 50,
  strengthRate: 0.04,
  dexterity: 30,
  dexterityRate: 0.04,
  intelligence: 40,
  intelligenceRate: 0.04,
  moveSpeed: 80,
  initialPhysicalAttackSpeed: 400,
  initialEvasion: 0,
  initialAccuracy: 70,
};

export function showAttributeWithLvl(lvl, baseAttribute, attributeRate) {
  let result = baseAttribute;
  for (let i = 1; i < lvl; i++) {
    result *= 1 + attributeRate;
  }
  return Number(result.toFixed(2));
}

export function showMaxHp(baseStr) {
  return (baseStr * 10).toFixed(2);
}

export function showPhysicalAttack(str, dex) {
  return ((str + dex) * 1).toFixed(2);
}

export function showStatusResistance(str) {
  return Math.min(str / 10, 50).toFixed(2);
}

export function showHpRecovery(str) {
  return (0.05 * str).toFixed(2);
}

export function showPhysicalAttackSpeed(initialPhysicalAttackSpeed, dex) {
  return (initialPhysicalAttackSpeed + dex).toFixed(2);
}

export function showEvasion(dex) {
  return (Math.min(dex / 10, 50)).toFixed(2);
}

export function showAccuracy(dex) {
  return (Math.min(70 + dex / 10, 100)).toFixed(2);
}

export function showManaPoints(int) {
  return (10 * int).toFixed(2);
}

export function showMagicAttack(int) {
  return (int * 1).toFixed(2);
}

export function showMpRecovery(int) {
  return (0.05 * int).toFixed(2);
}
