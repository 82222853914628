import React, { useEffect, useMemo } from "react";
import classes from "./Tokens.module.css";
import { Grid } from "@mui/material";
import { useState } from "react";
import { AuthClient } from "@dfinity/auth-client";
import { Actor, HttpAgent } from "@dfinity/agent";
import { collections, kernelCanister } from "../../../canisters";
import kernelDid from "../../../utils/candid/kernel.did";
import { SearchPanel } from "../../UI/SearchPanel/SearchPanel";
import { getParamOnPage, tokenIdentifier, validatePrincipal } from "../../../utils/utils";
import { TokenItem } from "../../UI/TokenItem/TokenItem";
import loader from "./../../../media/loader.gif";
import { useNavigate, useParams } from "react-router-dom";
import { MyPagination } from "../../Blocks/MyPagination/MyPagination";
import { Files } from "../Files/Files";
import { getFolder } from "../../../utils/eimoladUtils";
import { CollectionsWithFiles } from "../../../utils/ictokensConfig";

const getNftSupply = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor
    .getNftSupply(canister)
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log(err));
};

const getTotalSupply = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor
    .getTotalSupply(canister)
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log(err));
};

export const Tokens = ({ secondary_color, primary_color, address }) => {
  const [tokens, setTokens] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [nftSupply, setNftSupply] = useState(null);
  const [tokensSupply, setTokensSupply] = useState(null);
  // const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [tokensPerPage] = useState(20);
  const lastTokenIndex = currentPage * tokensPerPage;
  const firstTokenIndex = lastTokenIndex - tokensPerPage;

  // const [currentFile, setCurrentFile] = useState(null);

  // const tokensRef = useRef(false);
  const [files, setFiles] = useState(null);

  let currentTokens;

  let navigate = useNavigate();

  if (tokens) currentTokens = tokens.slice(firstTokenIndex, lastTokenIndex);

  const paginate = (pageNumber, previous, next) => {
    if (previous) {
      setCurrentPage(pageNumber - 1);
    }
    if (next) {
      setCurrentPage(pageNumber + 1);
    }
    setCurrentPage(pageNumber);
  };

  let id = 0;
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  let searchResults = [];

  const { collectionName } = useParams();

  let collectionId = getParamOnPage("id");

  let ledgerCanister = getParamOnPage("canister");

  let type = getParamOnPage("type");

  let file = getParamOnPage("file");

  let minIndex = Number(getParamOnPage("minIndex"));

  let maxIndex = Number(getParamOnPage("maxIndex"));

  useEffect(() => {
    if (collectionName !== "Dwarves" && collectionName !== "Weapons" && type === "nfts") {
      getFolder(ledgerCanister, collectionName, (data) => {
        // console.log(data);
        setFiles(data);
      });
    }
  }, []);

  const memoizedValue = useMemo(() => {
    let tokensArray = [];
    setCurrentPage(1);

    if (collectionId && ledgerCanister && collectionName) {
      // if (collectionName === "Weapons") setCurrentPage(1);
      if (type === "nfts") {
        getNftSupply(ledgerCanister, (data) => {
          setNftSupply(data);
        });
      }

      if (type === "token") {
        getTotalSupply(ledgerCanister, (data) => {
          setTokensSupply(data);
        });
      }
      if (collectionName === "Weapons" && searchTerm === "") {
        for (let i = minIndex; i < maxIndex; i++) {
          let token = {};
          token.index = i + 1;
          token.tid = tokenIdentifier(ledgerCanister, i);
          token.type = type;
          tokensArray.push(token);
        }
        setTokens(tokensArray);
        return tokensArray;
      } else {
        if (nftSupply !== null) {
          if (collectionName !== "Dwarves") {
            for (let i = 0; i < nftSupply; i++) {
              let token = {};
              token.index = i + 1;
              token.tid = tokenIdentifier(ledgerCanister, i);
              token.type = type;
              // token.name = "name";
              // getMD_asset(ledgerCanister, i, (data) => (token.name = data[0].replace(/_/g, " ")));
              tokensArray.push(token);
            }
          } else {
            for (let i = 0; i < nftSupply; i++) {
              let token = {};
              token.index = i + 1;
              token.tid = tokenIdentifier(ledgerCanister, i);
              token.type = type;
              tokensArray.push(token);
            }
          }

          setTokens(tokensArray);
          return tokensArray;
        }
      }
      if (type === "token") {
        if (tokensSupply !== null) {
          let token = {};
          token.title = collectionName;
          token.totalSupply = tokensSupply;
          token.type = type;
          tokensArray.push(token);
          setTokens(tokensArray);
          return tokensArray;
        }
      }
    }
  }, [file, nftSupply, tokensSupply, searchTerm]);

  useEffect(() => {
    if (collectionId && ledgerCanister && collectionName) {
      // if (collectionName === "Weapons") setCurrentPage(1);
      if (type === "nfts") {
        getNftSupply(ledgerCanister, (data) => {
          setNftSupply(data);
        });
      }
      if (type === "token") {
        getTotalSupply(ledgerCanister, (data) => {
          setTokensSupply(data);
        });
      }
    }
  }, [collectionId, ledgerCanister, collectionName, type, searchTerm, minIndex, maxIndex]);

  let results;

  if (tokens && currentTokens) {
    if (!searchTerm) {
      results = currentTokens;
      if (type === "nfts" && tokens && currentTokens) {
        // if (file === "none" && collectionName !== "Weapons") {
        //   results = tokens.filter((t) => t.name.toLowerCase().includes(file.toLowerCase()));
        // } else {
        searchResults = results.map((t) => (
          <TokenItem key={t.index} index={t.index} tid={t.tid} ledgerCanister={ledgerCanister} secondary_color={secondary_color} type={type} />
        ));
        // }
      }
    } else {
      if (validatePrincipal(searchTerm)) {
        results = tokens.filter((t) => t.tid.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
        if (type === "nfts") {
          searchResults = results.map((t) => (
            <TokenItem key={t.index} index={t.index} tid={t.tid} ledgerCanister={ledgerCanister} secondary_color={secondary_color} type={type} />
          ));
        }
      } else {
        results = tokens.filter((t) => t.index === Number(searchTerm));
        if (type === "nfts") {
          searchResults = results.map((t) => (
            <TokenItem key={t.index} index={t.index} tid={t.tid} ledgerCanister={ledgerCanister} secondary_color={secondary_color} type={type} />
          ));
        }
      }
    }
  }
  // console.log(tokens.length !== 0 && collectionName !== "Dwarves" && collectionName !== "Weapons" && !files);

  //useEffect(() => {
  // if (tokens.length !== 0) {
  // console.log(tokens[0].name);
  //if (tokens.length !== 0 && collectionName !== "Dwarves" && collectionName !== "Weapons" && !files) {
  //setFiles(makeFiles(tokens));
  //}
  // }
  //}, [tokens]);

  useEffect(() => {
    if (file) {
      if (file !== "null" && collectionName !== "Weapons" && files) {
        // console.log(tokens);
        // console.log(sortArrayByName(tokens, file));
        // console.log(files)
        setTokens(files[file.replace(/%20/g, " ")]);
      }
    }
  }, [file, collectionName, files]);

  async function handleSubmit(id, canister, collectionType, fileName) {
    navigate("?id=" + id + "&?canister=" + canister + "&?type=" + collectionType + "&?file=" + fileName);
  }

  return (
    <>
      {type === "nfts" ? (
        <div className="searchPanel">
          <SearchPanel
            title="Enter your mint number or identifier"
            searchTerm={searchTerm}
            handleChange={handleChange}
            color={primary_color}
            setSearchTerm={setSearchTerm}
            icon={true}
          ></SearchPanel>
        </div>
      ) : null}
      {searchResults.length !== 0 && !searchTerm && file!=='null' ? (
        <div className={classes.pagination}>
          <MyPagination
            key={1}
            primary_color={primary_color}
            page={currentPage}
            totalItems={tokens.length}
            itemsPerPage={tokensPerPage}
            paginate={paginate}
          />
        </div>
      ) : null}
      {file && file !== "null" && (
        <div className={classes.title__back}>
          <p
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit(collectionId, ledgerCanister, type, "null");
            }}
          >
            &larr; Back
          </p>
        </div>
      )}
      <Grid justifyContent="center" columns={{ md: 2 }} spacing={3} container>
        {searchResults.length > 0 && !CollectionsWithFiles.includes(collectionName) && searchResults}

        {collectionName === "Weapons" && file === "null" && !searchTerm && <Files collection="weapons" secondary_color={secondary_color} />}

        {CollectionsWithFiles.includes(collectionName) && file === "null" && files && !searchTerm && (
          <Files collection={collectionName} files={files} secondary_color={secondary_color} />
        )}

        {CollectionsWithFiles.includes(collectionName) && file !== "null" && searchResults}

        {CollectionsWithFiles.includes(collectionName) && searchTerm && searchResults}

        {searchResults.length === 0 && !CollectionsWithFiles.includes(collectionName) && (
          <div className="loader">
            <img src={loader} alt="loading" />
          </div>
        )}

        {CollectionsWithFiles.includes(collectionName) && !files && collectionName !== "Weapons" && (
          <div className="loader">
            <img src={loader} alt="loading" />
          </div>
        )}

        {/* {!searchTerm ? (
          file === "null" ? (
            collectionName !== "Weapons" ? (
              files ? (
                <Files collection={collectionName} files={files} secondary_color={secondary_color} />
              ) : (
                <div className="loader">
                  <img src={loader} alt="loading" />
                </div>
              )
            ) : (
              <Files collection="weapons" secondary_color={secondary_color} />
            )
          ) : (
            searchResults
          )
        ) : (
          searchResults
        )}
        {searchResults.length === 0 &&
        collectionName !== "Weapons" &&
        collectionName !== "Licenses" &&
        collectionName !== "Non-grade weapons" &&
        collectionName !== "non-grade armor" ? (
          <div className="loader">
            <img src={loader} alt="loading" />
          </div>
        ) : null} */}
      </Grid>
    </>
  );
};
