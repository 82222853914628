/* global BigInt */
import React, { useState, useEffect } from "react";
import classes from "./ModalWindow.module.css";
import cornerDecorRight from "./../../../media/cornerDecorRight.png";
import cornerDecorLeft from "./../../../media/cornerDecorLeft.png";

export const ModalWindow = ({ active, setActive, children }) => {
  const rootClasses = [classes.modal];
  if (active) {
    rootClasses.push(classes.act);
  }

  return (
    <div
      className={rootClasses.join(" ")}
      onClick={() => {
        setActive(false);
      }}
    >
      <div className={classes.warning_modal__content + " " + classes.warning} onClick={(e) => e.stopPropagation()}>
        <img className={classes.cornerDecorLeft} src={cornerDecorLeft} alt="Corner Decor" />
        <img className={classes.cornerDecorRight} src={cornerDecorRight} alt="Corner Decor" />
        <div className={classes.forBorderMarket}>{children}</div>
      </div>
    </div>
  );
};
