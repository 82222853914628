import React, { useState } from "react";
import classes from "./Index.module.css";
import { Card, Grid } from "@mui/material";
import { ProjectItem } from "../../UI/ProjectItem/ProjectItem";
import { SearchPanel } from "../../UI/SearchPanel/SearchPanel";
import cornerRight from "./../../../media/cornerDecorRight.png";
import cornerLeft from "./../../../media/cornerDecorLeft.png";
import plus from "./plus.png";

export const Index = ({ secondary_color, primary_color, projects, address, setSearchTermMenu }) => {
  const [searchTerm, setSearchTerm] = useState("");
  let searchResults;

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (projects) {
    let results;
    if (!searchTerm) {
      results = JSON.parse(projects);
      searchResults = results.map((p) => (
        <ProjectItem
          key={p[0]}
          id={p[0]}
          name={p[1].title}
          description={p[1].description}
          preview={Number(p[1].asset)}
          secondary_color={secondary_color}
        />
      ));
    } else {
      results = JSON.parse(projects).filter((p) => p[1].title.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      searchResults = results.map((p) => (
        <ProjectItem
          key={p[0]}
          id={p[0]}
          name={p[1].title}
          description={p[1].description}
          preview={Number(p[1].asset)}
          secondary_color={secondary_color}
        />
      ));
    }
  }
  return (
    <>
      <div className="searchPanel">
        <SearchPanel
          title="Enter project name"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleChange={handleChange}
          color={primary_color}
          icon={true}
        ></SearchPanel>
      </div>
      {searchResults?.length !== 0 ? (
        searchResults?.length === 64 ? (
          { searchResults }
        ) : (
          <Grid marginLeft={-3} justifyContent="left" columns={{ md: 3 }} spacing={3} container>
            {address ? (
              <>
                <Grid item>
                  <div className="gridItem disabled">
                    <img className={"gridItem__cornerDecorLeft"} src={cornerLeft} alt="Corner Decor" />
                    <img className={"gridItem__cornerDecorRight"} src={cornerRight} alt="Corner Decor" />
                    <Card className="card collectionCard" onClick={() => {}} style={{ backgroundColor: secondary_color }} key={-1}>
                      <div className={classes.addProject}>
                        <div className={classes.plus}></div>
                        <h2>Add project</h2>
                      </div>
                    </Card>
                  </div>
                </Grid>
                {searchResults}
              </>
            ) : (
              searchResults
            )}
          </Grid>
        )
      ) : (
        <h3 className="emptySearch">It seems there is no such address or project</h3>
      )}
    </>
  );
};
