import React, { useState } from "react";
import classes from "./TokenInfoContent.module.css";
import { Card, CardMedia, Paper, Typography, Skeleton } from "@mui/material";
import { ParamsBlock } from "../ParamsBlock/ParamsBlock";
import { useEffect } from "react";
import { getTokenDetails, getTokenMetaData, getTotalSupply } from "../../../utils/ictokensUtils";
import { getAssetName, getCharLvl, getItemStateFromTid, getMetaDataFromRef, getTokenInfo, getWeaponAssetName } from "../../../utils/eimoladUtils";

function sortObject(obj) {
  let sortedObject = {};
  sortedObject["type"] = obj["type"];
  delete obj["type"];
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      sortedObject[key] = obj[key];
    });
  return sortedObject;
}

export const TokenInfoContent = ({
  ledgerCanister,
  secondary_color,
  circulateTokens,
  projectName,
  collectionName,
  type,
  tid,
  index,
  setCopied,
  copied,
}) => {
  const [metadata, setMetadata] = useState([]);
  const [descValue, setDescValue] = useState("");
  const [totalSupply, setTotalSupply] = useState("loading");
  const [tokenMetadata, setTokenMetadata] = useState(null);
  const [tokenAttributes, setTokenAttributes] = useState(null);
  const [baseAttributes, setBaseAttributes] = useState(null);

  let token = {};
  let tokenParams = {};
  useEffect(() => {
    if (type === "nfts") {
      if (collectionName === "Weapons" || collectionName === "Dwarves") {
        getTokenInfo(collectionName.toLowerCase(), tid, (data) => {
          //FIXME: делается запрос в канистру Eimolad
          let collection = collectionName.toLowerCase();
          let tokenData;
          if (collection === "dwarves") {
            tokenData = data.tokenInfo[collection][0];
          }
          if (collection === "weapons") {
            tokenData = data.tokenInfo[collection][0][0];
            tokenData.rarity = data.tokenRarity[0];
            tokenData.status = data.tokenInfo.weapons[1];
          }
          let keys = Object.keys(tokenData);
          for (let j = 0; j < keys.length; j++) {
            tokenData.tid = tid;
            if (keys[j] === "position") {
              delete tokenData[keys[j]];
            }
            if (keys[j] === "rase") {
              tokenData.race = tokenData.rase;
              delete tokenData[keys[j]];
            }
            // if (keys[j] === "weaponType") {
            //   tokenData.type = tokenData.weaponType;
            //   delete tokenData[keys[j]];
            // }
            if (keys[j] === "weapon") {
              delete tokenData[keys[j]];
            }
            if (keys[j] === "rarityRate") {
              tokenData.nri = tokenData.rarityRate;
              delete tokenData[keys[j]];
            }
            if (keys[j] === "ledgerCanister") {
              tokenData["ledger canister"] = tokenData.ledgerCanister;
              delete tokenData[keys[j]];
            }
            if (keys[j] === "modelCanister") {
              tokenData["model canister"] = tokenData.modelCanister;
              delete tokenData[keys[j]];
            }
            // if (keys[j] === "state") {
            // tokenData["state"] = tokenData.state;
            // delete tokenData[keys[j]];
            // }
          }
          setMetadata(tokenData);
        });
      } else if (collectionName === "Licenses") {
        if (tokenAttributes) {
          setMetadata(JSON.parse(tokenAttributes));
        }
      } else if (collectionName === "Astronauts") {
        getTokenDetails(tid, ledgerCanister, (data) => {
          setMetadata({ owner: data.ok[0] });
        });
      } else {
        // console.log(ledgerCanister, tid);
        // getMDFromRef(ledgerCanister, tid, (data) => {
        //   console.log(data);
        // });
        // setMetadata({ status: "none" }); //TODO

        getItemStateFromTid(ledgerCanister, collectionName, tid, (data) => {
          if (tokenAttributes) {
            setMetadata({
              type: JSON.parse(tokenAttributes)["type"],
              rarity: " — ",
              state: "N/A for staking",
              status: data[0],
              ["token id"]: tid,
              ["ledger canister"]: ledgerCanister,
            });
          }
        });
      }

      if (collectionName === "Weapons") {
        getWeaponAssetName(index - 1, (data) => {
          getMetaDataFromRef(data, (data) => {
            console.log(data);
            setTokenAttributes(data.ok);
          });
        });
      }
      if (collectionName === "Dwarves" && tokenAttributes===null) {
        // getAttributes(tid, (data) => {

        getMetaDataFromRef(collectionName.toLocaleLowerCase(), (data) => {
          // setBaseAttributes(data);
          setBaseAttributes(JSON.parse(data.ok));
        });
        getCharLvl(tid, (data) => {
          setTokenAttributes({ lvl: data });
        });

        // });
      }
      if (collectionName === "Non-grade weapons" || collectionName === "non-grade armor") {
        // getMiniNftsAttributes(ledgerCanister, tid, (data) => {
        //   setTokenAttributes(data);
        // });
        getAssetName(ledgerCanister, index - 1, (data) => {
          getMetaDataFromRef(data[0], (data) => {
            // console.log(data);
            setTokenAttributes(data.ok);
          });
        });
        // getMDFromRef(ledgerCanister, tid, (data) => {
        //   setTokenAttributes(data.ok);
        // });
      }
      if (collectionName === "Licenses") {
        // getMDFromRef(ledgerCanister, tid, (data) => {
        //   setTokenAttributes(data.ok);
        // });
        getAssetName(ledgerCanister, index - 1, (data) => {
          getMetaDataFromRef(data[0], (data) => {
            // console.log(data);
            setTokenAttributes(data.ok);
          });
        });
      }
    }
    if (type === "token") {
      getTotalSupply(ledgerCanister, (data) => {
        setTotalSupply(data);
      });
    }
    if (type === "icrcToken") {
      getTokenMetaData(ledgerCanister, (data) => {
        setTokenMetadata(data);
      });
    }

    if (collectionName === "Leather") setDescValue("Rough leather made from animal skins. It is most often used for making light armor."); //FIXME: временная мера
  }, [tokenAttributes]); //TODO tokenAttributes

  // console.log(metadata);

  if (type === "nfts") {
    // if (collectionName === "Weapons") {
    //   token.metadata = sortObject(metadata);
    //   console.log(1);
    // } else {
    token.metadata = metadata;
    // }
  }
  if (type === "icrcToken" && tokenMetadata) {
    tokenParams = {
      ["Token symbol"]: tokenMetadata.token_symbol,
      ["Token standart"]: tokenMetadata.token_standart,
      ["Circulate Tokens"]: circulateTokens,
      ["CB capacity"]: tokenMetadata.CB_capacity,
      ["Current CB value"]: tokenMetadata.current_CB_value,
      ["Min. CB value"]: tokenMetadata.min_CB_value + "%",
      ["Royalty"]: tokenMetadata.royalty + "%",
      ["Royalty wallet"]: tokenMetadata.royaltyWallet,
      ["Sns canister"]: tokenMetadata.snsCanister,
      ["Token canister"]: tokenMetadata.token_canister,
    };
  }
  if (type === "token")
    tokenParams = {
      ["total Supply"]: totalSupply,
      ["circulate Tokens"]: circulateTokens,
    };

  if (type === "nfts") {
    return (
      <div>
        <div className={classes.titles}>
          <div className={classes.names}>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-2);
              }}
            >
              {projectName}
            </Typography>
            <Typography>&#10132;</Typography>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {collectionName}
            </Typography>
          </div>
          <Typography>#{index}</Typography>
        </div>
        <div className={classes.thumbAndParamsContainer}>
          <div className={classes.tokenSymbolAndThumbContainer}>
            <div className={classes.tokenSymbol}>
              <Paper
                style={{
                  width: "100%",
                  height: "auto",
                  // overflowX: "hidden",
                  // overflowY: "scroll",
                  // scrollbarColor: secondary_color,
                  backgroundColor: secondary_color,
                  color: "#c7c7c7",
                  boxShadow: "none",
                }}
              >
                {tid ? (
                  <div
                    className={classes.tokenSymbol__text}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (token.metadata["ledger canister"]) {
                        window.open("https://" + token.metadata["ledger canister"] + ".raw.ic0.app/?cc=0&tokenid=" + tid);
                      } else {
                        window.open("https://" + ledgerCanister + ".raw.ic0.app/&tokenid=" + tid);
                      }
                    }}
                  >
                    <b style={{ fontSize: 25, padding: 0 }}>View NFT ON-CHAIN</b>
                  </div>
                ) : (
                  <Skeleton style={{ margin: "0px 10px 0px 10px" }} height={"100%"} animation="wave" />
                )}
              </Paper>
            </div>
            <div className={classes.thumbnailNft}>
              <Card variant="outlined" style={{ width: "100%", height: "100%", backgroundColor: secondary_color }}>
                {token.metadata["ledger canister"] !== undefined ? (
                  collectionName === "Dwarves" ? (
                    <iframe
                      id="iframeName"
                      scrolling="no"
                      frameBorder={0}
                      className={classes.iframe}
                      title="iframe"
                      src={"https://" + token.metadata["ledger canister"] + ".raw.ic0.app/?cc=0&tokenid=" + tid}
                      alt=""
                    ></iframe>
                  ) : (
                    <CardMedia
                      className="cardMedia"
                      component="img"
                      height="300"
                      src={"https://" + token.metadata["ledger canister"] + ".raw.ic0.app/?type=thumbnail&tokenid=" + tid}
                      alt="Thumbnail"
                    />
                  )
                ) : (
                  // <Skeleton style={{ margin: "0px 10px 0px 10px" }} height={"100%"} animation="wave" />

                  <CardMedia
                    className="cardMedia"
                    component="img"
                    height="300"
                    src={"https://" + ledgerCanister + ".raw.ic0.app/?type=thumbnail&tokenid=" + tid}
                    alt="Thumbnail"
                  />
                )}
              </Card>
            </div>
          </div>
          <div className={classes.params}>
            <Paper
              className={classes.params__paper}
              variant="outlined"
              style={{
                width: "100%",
                height: "435px",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarColor: secondary_color,
                backgroundColor: secondary_color,
                color: "#c7c7c7",
              }}
            >
              <div className={classes.params__items}>
                <ParamsBlock
                  collectionName={collectionName}
                  type={type}
                  metadata={collectionName === "Licenses" ? metadata : token.metadata}
                  baseAttributes={baseAttributes}
                  attributes={tokenAttributes}
                  setCopied={setCopied}
                  copied={copied}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
  if (type === "icrcToken") {
    return (
      <div>
        <div className={classes.titles}>
          <div className={classes.names}>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {projectName}
            </Typography>
            <Typography>&#10132;</Typography>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {collectionName}
            </Typography>
          </div>
        </div>
        {/* <div className={classes.description}>
          <Paper fullWidth sx={{ backgroundColor: "rgba(59,59,59,0.5)" }}>
            <h4>Description</h4>
            <p>{descValue}</p>
          </Paper>
        </div> */}
        <div className={classes.thumbAndParamsContainer}>
          <div className={classes.tokenSymbolAndThumbContainer}>
            <div className={classes.tokenSymbol}>
              <Paper
                style={{
                  width: "100%",
                  height: "auto",
                  // overflowX: "hidden",
                  // overflowY: "scroll",
                  // scrollbarColor: secondary_color,
                  backgroundColor: secondary_color,
                  color: "#c7c7c7",
                  boxShadow: "none",
                }}
              >
                {tokenParams["Token symbol"] ? (
                  <div className={classes.tokenSymbol__text}>
                    <b>{tokenParams["Token symbol"]}</b>
                  </div>
                ) : (
                  <Skeleton style={{ margin: "0px 10px 0px 10px" }} height={"100%"} animation="wave" />
                )}
              </Paper>
            </div>
            <div className={classes.thumbnail}>
              <Card variant="outlined" style={{ width: "100%", height: "100%", backgroundColor: secondary_color }}>
                {ledgerCanister !== undefined ? (
                  <CardMedia
                    className="cardMedia"
                    component="img"
                    height="290"
                    src={"https://" + ledgerCanister + ".raw.ic0.app/?asset=0"}
                    alt="Thumbnail"
                  />
                ) : (
                  <Skeleton style={{ margin: "0px 10px 0px 10px" }} height={"100%"} animation="wave" />
                )}
              </Card>
            </div>
          </div>

          <div className={classes.params}>
            <Paper
              className={classes.params__paper}
              variant="outlined"
              style={{
                width: "100%",
                height: "435px",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarColor: secondary_color,
                backgroundColor: secondary_color,
                color: "#c7c7c7",
              }}
            >
              <div className={classes.params__items}>
                <ParamsBlock type={type} setCopied={setCopied} copied={copied} metadata={tokenParams} />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={classes.titles}>
          <div className={classes.names}>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {projectName}
            </Typography>
            <Typography>&#10132;</Typography>
            <Typography
              className={classes.name}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              {collectionName}
            </Typography>
          </div>
        </div>
        <div className={classes.description}>
          <Paper fullWidth sx={{ backgroundColor: "rgba(59,59,59,0.5)" }}>
            <h4>Description</h4>
            <p>{descValue}</p>
          </Paper>
        </div>
        <div className={classes.thumbAndParamsContainer}>
          <div style={window.innerWidth > 768 ? { marginRight: "15px" } : null} className={classes.thumbnailToken}>
            <Card variant="outlined" style={{ width: "100%", height: "100%", backgroundColor: secondary_color }}>
              {ledgerCanister !== undefined ? (
                <CardMedia
                  className="cardMedia"
                  component="img"
                  height="290"
                  src={"https://" + ledgerCanister + ".raw.ic0.app/?asset=0"}
                  alt="Thumbnail"
                />
              ) : (
                <Skeleton style={{ margin: "0px 10px 0px 10px" }} height={"100%"} animation="wave" />
              )}
            </Card>
          </div>
          <div className={classes.params}>
            <Paper
              className={classes.params__paper}
              variant="outlined"
              style={{
                width: "100%",
                height: "300px",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarColor: secondary_color,
                backgroundColor: secondary_color,
                color: "#c7c7c7",
              }}
            >
              <div className={classes.params__items}>
                <ParamsBlock metadata={tokenParams} />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
};
