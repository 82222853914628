export default ({ IDL }) => {
  const CanisterIdentifier = IDL.Text;
  const CollectionIndex = IDL.Nat32;
  const Collection = IDL.Record({
    'asset' : IDL.Nat,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'ledgerCanister' : CanisterIdentifier,
    'project' : CollectionIndex,
    'collectionType' : IDL.Text,
  });
  const File = IDL.Record({
    'data' : IDL.Vec(IDL.Vec(IDL.Nat8)),
    'ctype' : IDL.Text,
  });
  const Asset = IDL.Record({ 'name' : IDL.Text, 'payload' : File });
  const Result = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Null });
  const Project = IDL.Record({
    'title' : IDL.Text,
    'asset' : IDL.Nat,
    'owner' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'verify' : IDL.Bool,
  });
  const AccountIdentifier__1 = IDL.Text;
  const AccountIdentifier = IDL.Text;
  const User__1 = IDL.Variant({
    'principal' : IDL.Principal,
    'address' : AccountIdentifier,
  });
  const TokenIdentifier__1 = IDL.Text;
  const Time = IDL.Int;
  const TransferInfo = IDL.Record({
    'to' : User__1,
    'token' : TokenIdentifier__1,
    'from' : User__1,
    'time' : Time,
    'amount' : IDL.Nat,
  });
  const Tokens = IDL.Nat;
  const User = IDL.Variant({
    'principal' : IDL.Principal,
    'address' : AccountIdentifier,
  });
  const Memo = IDL.Vec(IDL.Nat8);
  const Timestamp = IDL.Nat64;
  const Transfer = IDL.Record({
    'to' : User,
    'fee' : IDL.Opt(Tokens),
    'from' : User,
    'memo' : IDL.Opt(Memo),
    'created_at_time' : IDL.Opt(Timestamp),
    'amount' : Tokens,
  });
  const TxKind = IDL.Variant({
    'Burn' : IDL.Null,
    'Mint' : IDL.Null,
    'Transfer' : IDL.Null,
  });
  const Transaction = IDL.Record({
    'fee' : Tokens,
    'args' : Transfer,
    'kind' : TxKind,
    'timestamp' : Timestamp,
  });
  const TokenIdentifier = IDL.Text;
  const ResultTrans = IDL.Record({
    'trans' : IDL.Vec(Transaction),
    'size' : IDL.Nat32,
  });
  const Balance = IDL.Nat;
  const TokenInfo = IDL.Record({
    'token_symbol' : IDL.Text,
    'token_canister' : IDL.Text,
    'CB_capacity' : IDL.Nat,
    'current_CB_value' : Balance,
    'token_standart' : IDL.Text,
    'min_CB_value' : IDL.Nat,
    'snsCanister' : IDL.Text,
    'royaltyWallet' : IDL.Text,
    'royalty' : IDL.Nat,
  });
  const TransferId = IDL.Nat32;
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const HttpRequest = IDL.Record({
    'url' : IDL.Text,
    'method' : IDL.Text,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
  });
  const HttpStreamingCallbackToken = IDL.Record({
    'key' : IDL.Text,
    'sha256' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'index' : IDL.Nat,
    'content_encoding' : IDL.Text,
  });
  const HttpStreamingCallbackResponse = IDL.Record({
    'token' : IDL.Opt(HttpStreamingCallbackToken),
    'body' : IDL.Vec(IDL.Nat8),
  });
  const HttpStreamingStrategy = IDL.Variant({
    'Callback' : IDL.Record({
      'token' : HttpStreamingCallbackToken,
      'callback' : IDL.Func(
          [HttpStreamingCallbackToken],
          [HttpStreamingCallbackResponse],
          ['query'],
        ),
    }),
  });
  const HttpResponse = IDL.Record({
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
    'streaming_strategy' : IDL.Opt(HttpStreamingStrategy),
    'status_code' : IDL.Nat16,
  });
  const ProjectIndex = IDL.Nat32;
  const Result_1 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  return IDL.Service({
    'acceptCycles' : IDL.Func([], [], []),
    'addCollection' : IDL.Func([Collection, Asset], [Result], []),
    'addProject' : IDL.Func([Project, Asset], [Result], []),
    'availableCycles' : IDL.Func([], [IDL.Nat], ['query']),
    'findTransactionsFromAid' : IDL.Func(
        [IDL.Text, AccountIdentifier__1],
        [IDL.Vec(TransferInfo)],
        [],
      ),
    'findTransactionsFromAid_ICRC' : IDL.Func(
        [IDL.Text, AccountIdentifier__1],
        [IDL.Vec(Transaction)],
        [],
      ),
    'findTransactionsFromTID' : IDL.Func(
        [IDL.Text, TokenIdentifier],
        [IDL.Vec(TransferInfo)],
        [],
      ),
    'getAttributes' : IDL.Func(
        [IDL.Text, TokenIdentifier],
        [IDL.Opt(IDL.Text)],
        [],
      ),
    'getCanisterMemorySize' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'getCanistersArray' : IDL.Func([], [IDL.Vec(IDL.Text)], []),
    'getCirculateTokens' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'getCollectionsAssets' : IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
    'getCycleBalance' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'getICRCTransactions' : IDL.Func(
        [IDL.Text, IDL.Nat32, IDL.Nat32],
        [ResultTrans],
        [],
      ),
    'getNftSupply' : IDL.Func([IDL.Text], [IDL.Nat32], []),
    'getTokenMetaData' : IDL.Func([IDL.Text], [TokenInfo], []),
    'getTotalSupply' : IDL.Func([IDL.Text], [IDL.Nat32], []),
    'getTransactions' : IDL.Func(
        [IDL.Text],
        [IDL.Vec(IDL.Tuple(TransferId, TransferInfo))],
        [],
      ),
    'http_request' : IDL.Func([HttpRequest], [HttpResponse], ['query']),
    'http_request_streaming_callback' : IDL.Func(
        [HttpStreamingCallbackToken],
        [HttpStreamingCallbackResponse],
        ['query'],
      ),
    'showCollectionList' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(CollectionIndex, Collection))],
        [],
      ),
    'showProjectList' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(ProjectIndex, Project))],
        [],
      ),
    'updateAsset' : IDL.Func([IDL.Text, Asset], [Result_1], []),
    'updateCollection' : IDL.Func([CollectionIndex, Collection], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };