import React from "react";
import { Paper } from "@mui/material";
import { TokenInfoContent } from "../../Blocks/TokenInfoContent/TokenInfoContent";
import { TransactionsAccordion } from "../../UI/TransactionsAccordion/TransactionsAccordion";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getParamOnPage } from "../../../utils/utils";
import { useEffect } from "react";
import { findTransactionsFromTID, getCirculateTokens, getICRCTransactions, getTransactions } from "../../../utils/ictokensUtils";

export const TokenInfo = ({ token, primary_color, secondary_color, copied, setCopied }) => {
  const [transactions, setTransactions] = useState(null);
  const [circulateTokens, setCirculateTokens] = useState("loading");
  const [totalRows, setTotalRows] = useState(0);
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let navigate = useNavigate();

  async function handleSubmitTokenInfo(name, id, ledgerCanister, type, page) {
    navigate("?id=" + id + "&?canister=" + ledgerCanister + "&?type=" + type + "&?page=" + page);
  }

  const { projectName, collectionName, tid } = useParams();

  let ledgerCanister = getParamOnPage("canister");

  let type = getParamOnPage("type");

  let index = getParamOnPage("index");

  let id = getParamOnPage("id");

  let page = getParamOnPage("page");

  // let rowsPerPage=getParamOnPage("rowsPerPage");
  if (transactions == null && ledgerCanister) {
    if (type === "nfts") {
      findTransactionsFromTID(ledgerCanister, tid, (data) => {
        setTransactions(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
      });
    }

    if (type === "token") {
      getTransactions(ledgerCanister, (data) => {
        setTransactions(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
      });
    }
  }
  if ((circulateTokens === "loading" && ledgerCanister && type === "token") || type === "icrcToken") {
    getCirculateTokens(ledgerCanister, (data) => {
      setCirculateTokens(Number(data));
    });
  }

  useEffect(() => {
    if (type === "icrcToken") {
      // getICRCTransactions(ledgerCanister, (data) => {
      //   setTransactions(JSON.stringify(data, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
      // });
      setTransactions("loading");
      handleSubmitTokenInfo(collectionName, id, ledgerCanister, type, page);
      getICRCTransactions(ledgerCanister, Number(page), rowsPerPage, (data) => {
        // console.log(data);
        setTransactions(JSON.stringify(data.trans, (_, v) => (typeof v === "bigint" ? v.toString() : v)));
        // console.log(transactions);
        setTotalRows(data.size);
      });
    }
  }, [page, rowsPerPage]);

  // console.log(page)

  return (
    <Paper style={{ backgroundColor: secondary_color, color: "#c7c7c7" }}>
      {type === "nfts" ? (
        <TokenInfoContent
          type={type}
          tid={tid}
          index={index}
          projectName={projectName}
          collectionName={collectionName}
          ledgerCanister={ledgerCanister}
          primary_color={primary_color}
          secondary_color={secondary_color}
          setCopied={setCopied}
          copied={copied}
        />
      ) : (
        <TokenInfoContent
          type={type}
          projectName={projectName}
          collectionName={collectionName}
          circulateTokens={circulateTokens}
          ledgerCanister={ledgerCanister}
          primary_color={primary_color}
          secondary_color={secondary_color}
          setCopied={setCopied}
          copied={copied}
        />
      )}

      <TransactionsAccordion
        type={type}
        setCopied={setCopied}
        copied={copied}
        token={token}
        transactions={transactions}
        primary_color={primary_color}
        secondary_color={secondary_color}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={Number(page)}
        collectionName={collectionName}
        // setPage={setPage}
      />
    </Paper>
  );
};
