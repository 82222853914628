import { Card, CardActionArea, CardContent, CardMedia, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthClient } from "@dfinity/auth-client";
import { Actor, HttpAgent } from "@dfinity/agent";
import { kernelCanister } from "../../../canisters";
import kernelDid from "../../../utils/candid/kernel.did";
import classes from "./CollectionItem.module.css";
import { useEffect } from "react";
import cornerLeft from "./../../../media/cornerDecorLeft.png";
import cornerRight from "./../../../media/cornerDecorRight.png";
import { CollectionsWithFiles } from "../../../utils/ictokensConfig";

const getCycleBalance = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor.getCycleBalance(canister).then((data) => {
    callback(data);
  });
};

const getCanisterMemorySize = async (canister, callback) => {
  const authClient = await AuthClient.create();

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });

  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  await actor.getCanisterMemorySize(canister).then((data) => {
    callback(data);
  });
};

export const CollectionItem = ({ id, name, description, preview, secondary_color, canister, collectionType }) => {
  const [cyclesValue, setCyclesValue] = useState("");
  const [memoValue, setMemoValue] = useState("");

  let navigate = useNavigate();
  async function handleSubmitCollections(name, id, canister, collectionType) {
    if (collectionType === "nfts" && CollectionsWithFiles.includes(name)) {
      navigate(name + "?id=" + id + "&?canister=" + canister + "&?type=" + collectionType + "&?file=" + "null");
    } else {
      navigate(name + "?id=" + id + "&?canister=" + canister + "&?type=" + collectionType + "&?page=" + 0);
    }
  }
  useEffect(() => {
    getCycleBalance(canister, (data) => {
      setCyclesValue(String((Number(data) / 1_000_000_000_000).toFixed(2)));
    });
    getCanisterMemorySize(canister, (data) => {
      setMemoValue(String((Number(data) / 1024 / 1024).toFixed(2)));
    });
  }, []);

  return (
    <Grid item>
      <div className="gridItem">
        <img className={"gridItem__cornerDecorLeft"} src={cornerLeft} alt="Corner Decor" />
        <img className={"gridItem__cornerDecorRight"} src={cornerRight} alt="Corner Decor" />
        <Card className="card collectionCard" style={{ backgroundColor: secondary_color }} key={id}>
          <CardActionArea>
            <div
              onClick={() => {
                handleSubmitCollections(name, id, canister, collectionType);
              }}
            >
              <CardMedia component="img" image={"https://" + kernelCanister + ".raw.ic0.app/?asset=" + preview} alt="img" />
              <CardContent sx={{ paddingTop: 0 }}>
                <Typography style={{ color: "rgba(236, 131, 46, 1)", fontSize: "19px" }}>
                  {/* FIXME: временная мера */}
                  {name === "Dwarves" ? "Dwarf Elders" : name === "Weapons" ? "ANCIENT WEAPONS" : name}
                </Typography>
                <Typography>{description}</Typography>
                {cyclesValue === "" ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography style={{ textTransform: "none", color: "rgba(236, 131, 46, 1)", paddingTop: "5px" }} fontSize={10}>
                    {cyclesValue} T Cycles
                  </Typography>
                )}
                {memoValue === "" ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography style={{ textTransform: "none", color: "rgba(236, 131, 46, 1)" }} fontSize={10}>
                    {memoValue} Mb
                  </Typography>
                )}
              </CardContent>
            </div>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
};
