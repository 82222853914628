import { Button, Paper, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { DragAndDrop } from "../../UI/DragAndDrop/DragAndDrop";
import classes from "./ProjectCreationForm.module.css";
import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import kernelDid from "../../../utils/candid/kernel.did";
import { kernelCanister } from "../../../canisters";

const addProject = async (titleValue, owner, name, descriptionValue, file_name, callback) => {

  const authClient = await AuthClient.create({
    _storage: localStorage.getItem("ic-delegation"),
  });

  const agent = new HttpAgent({
    host: "https://ic0.app",
    identity: authClient.getIdentity(),
  });
  const actor = Actor.createActor(kernelDid, {
    agent: agent,
    canisterId: kernelCanister,
  });

  let args = {
    title: titleValue,
    asset: 0,
    owner: owner,
    name: "naasdme",
    description: descriptionValue,
    verify: true
  };

  let addAsset = async (name, file_name) => {
    const payload_file = await loadFile(file_name);
    const payload = { data: [payload_file], ctype: "image/jpg" };
    const Asset = { name: name, payload: payload };
    return Asset;
  };

  let asset = addAsset(name, file_name).then((data)=>{
    actor.addProject(args, data).then((data) => {
      callback(data);
    })}
  );

};

let loadFile = async (bfile) => {
  let arrOfS = [];
  let i = 0;
  while (i < bfile.length) {
    arrOfS.push(bfile[i]);
    i++;
  }
  return arrOfS;
};

export const ProjectCreationForm = ({ secondary_color }) => {
  const [titleValue, setTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [dropedFile, setDropedFile] = useState(null);
  const [dropedFileName, setDropedFileName] = useState("");

  console.log(titleValue, descriptionValue, dropedFile, dropedFileName);

  function onDropHandler(e) {
    e.preventDefault();
    let files = [...e.dataTransfer.files];
    var reader = new FileReader();
    reader.onload = function (e) {
      var arrayBuffer = reader.result;
      var view = new Uint8Array(arrayBuffer);
      setDropedFile(view);
      setDropedFileName(files[0].name);
    };
    reader.readAsArrayBuffer(files[0]);
  }

  return (
    <Paper style={{ width: "100%", height: "100%", backgroundColor: secondary_color, color: "#c7c7c7" }}>
      <div className={classes.form}>
        <h2>Add project</h2>

        <TextField
          id="outlined-basic"
          value={titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
          className={classes.form__item}
          label="Title"
          variant="outlined"
          style={{
            padding: "0",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "0",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <TextField
          id="outlined-basic"
          value={descriptionValue}
          onChange={(e) => setDescriptionValue(e.target.value)}
          className={classes.form__item}
          multiline
          label="Description"
          variant="outlined"
          style={{
            padding: "0",
            marginTop: "15px",
          }}
          InputProps={{
            style: {
              color: "white",
              padding: "30px 20px 30px 20px",
              backgroundColor: "rgba(59,59,59,0.5)",
            },
          }}
          InputLabelProps={{
            style: {
              padding: "0",
              color: "#c7c7c7",
            },
          }}
        />

        <DragAndDrop onDropHandler={onDropHandler} className={classes.form__item} />

        <Button
          onClick={() => {
            addProject(
              titleValue,
              "1c7a48ba6a562aa9eaa2481a9049cdf0433b9738c992d698c31d8abf89cadc79",
              dropedFileName,
              descriptionValue,
              dropedFile,
              (data) => {
                console.log(data);
              }
            );
          }}
          style={{ marginTop: "15px" }}
          className={classes.form__item}
          sx={{ height: "40px" }}
          variant="contained"
        >
          Add project
        </Button>
      </div>
    </Paper>
  );
};
