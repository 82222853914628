export default ({ IDL }) => {
  const TokenIdentifier = IDL.Text;
  const Name = IDL.Text;
  const Amount = IDL.Nat;
  const Price = IDL.Tuple(Name, Amount);
  const Product = IDL.Variant({
    'nft' : TokenIdentifier,
    'set' : IDL.Vec(TokenIdentifier),
    'token' : Price,
  });
  const AccountIdentifier = IDL.Text;
  const SubAccount = IDL.Vec(IDL.Nat8);
  const AccountIdentifier__3 = IDL.Vec(IDL.Nat8);
  const AccountBalanceArgs = IDL.Record({ 'account' : AccountIdentifier__3 });
  const Tokens__1 = IDL.Record({ 'e8s' : IDL.Nat64 });
  const SubAccount__2 = IDL.Vec(IDL.Nat8);
  const Account = IDL.Record({
    'owner' : IDL.Principal,
    'subaccount' : IDL.Opt(SubAccount__2),
  });
  const AccountIdentifier__2 = IDL.Text;
  const User = IDL.Variant({
    'principal' : Account,
    'address' : AccountIdentifier__2,
  });
  const Balance__1 = IDL.Nat;
  const ListRequest = IDL.Record({
    'from_subaccount' : SubAccount,
    'price' : Price,
    'product' : Product,
  });
  const Result_10 = IDL.Variant({ 'ok' : AccountIdentifier, 'err' : IDL.Text });
  const CanisterIdentifier = IDL.Text;
  const ReferenceBook = IDL.Record({
    'metadata' : IDL.Text,
    'name' : Name,
    'tokenType' : IDL.Text,
    'canisterId' : CanisterIdentifier,
  });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const CommonError = IDL.Variant({
    'InvalidToken' : TokenIdentifier,
    'Other' : IDL.Text,
  });
  const Result_2 = IDL.Variant({ 'ok' : IDL.Text, 'err' : CommonError });
  const ListingId = IDL.Nat32;
  const Result_9 = IDL.Variant({ 'ok' : ListingId, 'err' : IDL.Text });
  const Result_8 = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const CurrentEquip = IDL.Vec(IDL.Text);
  const CurrentEquipment = IDL.Vec(IDL.Nat8);
  const UpdatedUserAccount = IDL.Record({
    'equipment' : CurrentEquipment,
    'name' : IDL.Text,
    'quest' : IDL.Vec(IDL.Text),
    'charId' : TokenIdentifier,
    'position' : IDL.Vec(IDL.Float64),
  });
  const CanisterMemorySize = IDL.Nat;
  const Rases = IDL.Text;
  const RarityRate = IDL.Float64;
  const TokenState = IDL.Text;
  const Weapons = IDL.Record({
    'weaponType' : IDL.Text,
    'ledgerCanister' : CanisterIdentifier,
    'state' : TokenState,
    'modelCanister' : CanisterIdentifier,
  });
  const CharactersMetadata = IDL.Record({
    'equipment' : CurrentEquipment,
    'rase' : Rases,
    'rarityRate' : RarityRate,
    'ledgerCanister' : CanisterIdentifier,
    'state' : TokenState,
    'position' : IDL.Vec(IDL.Float64),
    'modelCanister' : CanisterIdentifier,
    'weapon' : IDL.Opt(Weapons),
  });
  const Time = IDL.Int;
  const Contract = IDL.Record({
    'status' : IDL.Text,
    'reward' : Price,
    'offer' : AccountIdentifier,
    'consumer_index' : IDL.Nat,
    'time' : Time,
    'subaccount' : IDL.Vec(IDL.Nat8),
    'license_tid' : TokenIdentifier,
    'consumer' : AccountIdentifier,
    'price' : IDL.Vec(Price),
    'product' : IDL.Text,
  });
  const Item = IDL.Record({ 'metaData' : IDL.Text, 'state' : IDL.Text });
  const UserAccount = IDL.Record({
    'current_equip' : CurrentEquip,
    'equipment' : CurrentEquipment,
    'name' : IDL.Text,
    'quest' : IDL.Vec(IDL.Text),
    'level' : IDL.Nat,
    'experience' : IDL.Nat,
    'charId' : TokenIdentifier,
    'position' : IDL.Vec(IDL.Float64),
  });
  const UserName = IDL.Record({ 'tid' : TokenIdentifier, 'name' : IDL.Text });
  const SCharacter = IDL.Record({
    'tid' : TokenIdentifier,
    'canister' : CanisterIdentifier,
    'index' : IDL.Nat32,
  });
  const SWeapon = IDL.Record({
    'tid' : TokenIdentifier,
    'canister' : CanisterIdentifier,
    'index' : IDL.Nat32,
  });
  const StakeAdit = IDL.Record({
    'aid' : AccountIdentifier,
    'character' : SCharacter,
    'lastClaimTime' : Time,
    'eAdit_amount' : IDL.Nat,
    'startStaketime' : Time,
    'weapon' : SWeapon,
  });
  const RankValue = IDL.Nat;
  const StakeCoal = IDL.Record({
    'aid' : AccountIdentifier,
    'rank' : RankValue,
    'eCoal_amount' : IDL.Nat,
    'lastClaimTime' : Time,
    'weapon_1' : SWeapon,
    'weapon_2' : SWeapon,
    'startStaketime' : Time,
  });
  const Stake = IDL.Record({
    'aid' : AccountIdentifier,
    'character' : SCharacter,
    'rank' : RankValue,
    'rarityRate' : IDL.Nat,
    'lastClaimTime' : Time,
    'eGold_amount' : IDL.Nat,
    'startStaketime' : Time,
    'weapon' : SWeapon,
  });
  const StakeOre = IDL.Record({
    'aid' : AccountIdentifier,
    'character' : SCharacter,
    'rank' : RankValue,
    'lastClaimTime' : Time,
    'startStaketime' : Time,
    'eOre_amount' : IDL.Nat,
    'weapon' : SWeapon,
  });
  const Result_7 = IDL.Variant({ 'ok' : ReferenceBook, 'err' : IDL.Text });
  const TokenIdentifier__1 = IDL.Text;
  const Collections = IDL.Variant({
    'weapons' : TokenIdentifier__1,
    'dwarves' : TokenIdentifier__1,
  });
  const TokenInfo = IDL.Variant({
    'weapons' : IDL.Tuple(IDL.Opt(Weapons), IDL.Text),
    'dwarves' : IDL.Opt(CharactersMetadata),
  });
  const TokenInfoRarity = IDL.Record({
    'tokenRarity' : IDL.Opt(IDL.Text),
    'tokenInfo' : TokenInfo,
  });
  const TokenRarity = IDL.Record({ 'tokenRarity' : IDL.Text });
  const Result_6 = IDL.Variant({
    'ok' : IDL.Tuple(AccountIdentifier, Price),
    'err' : IDL.Text,
  });
  const Result_5 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  const Result_4 = IDL.Variant({
    'ok' : IDL.Tuple(
      UpdatedUserAccount,
      IDL.Vec(IDL.Tuple(IDL.Text, ReferenceBook)),
    ),
    'err' : CommonError,
  });
  const SubAccount__1 = IDL.Vec(IDL.Nat8);
  const Balance = IDL.Nat;
  const AccountIdentifier__1 = IDL.Text;
  const TransferResponse = IDL.Variant({
    'ok' : Balance,
    'err' : IDL.Variant({
      'CannotNotify' : AccountIdentifier__1,
      'InsufficientBalance' : IDL.Null,
      'InvalidToken' : TokenIdentifier__1,
      'Rejected' : IDL.Null,
      'Unauthorized' : AccountIdentifier__1,
      'Other' : IDL.Text,
    }),
  });
  const Result_3 = IDL.Variant({ 'ok' : IDL.Text, 'err' : TransferResponse });
  const Tokens = IDL.Nat;
  const TxIndex = IDL.Nat;
  const Timestamp = IDL.Nat64;
  const TransferError = IDL.Variant({
    'GenericError' : IDL.Record({
      'message' : IDL.Text,
      'error_code' : IDL.Nat,
    }),
    'TemporarilyUnavailable' : IDL.Null,
    'BadBurn' : IDL.Record({ 'min_burn_amount' : Tokens }),
    'Duplicate' : IDL.Record({ 'duplicate_of' : TxIndex }),
    'BadFee' : IDL.Record({ 'expected_fee' : Tokens }),
    'CreatedInFuture' : IDL.Record({ 'ledger_time' : Timestamp }),
    'TooOld' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : Tokens }),
  });
  const TransferResult = IDL.Variant({ 'Ok' : Tokens, 'Err' : TransferError });
  const Result = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Null });
  const SaveEq = IDL.Record({ 'tid' : TokenIdentifier, 'state' : IDL.Text });
  return IDL.Service({
    '_getDisbursements' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(Product, AccountIdentifier, SubAccount))],
        ['query'],
      ),
    '_getFailedDisbursements' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(Product, AccountIdentifier, SubAccount))],
        ['query'],
      ),
    'acceptCycles' : IDL.Func([], [], []),
    'account_balance_ic' : IDL.Func([AccountBalanceArgs], [Tokens__1], []),
    'account_balance_icrc' : IDL.Func([IDL.Text, User], [Balance__1], []),
    'addListing' : IDL.Func([ListRequest], [Result_10], []),
    'addToReferenceBook' : IDL.Func([ReferenceBook], [], []),
    'adminKillHeartBeat' : IDL.Func([], [], []),
    'adminStartHeartBeat' : IDL.Func([], [], []),
    'availableCycles' : IDL.Func([], [IDL.Nat], ['query']),
    'changeCanisterInRef' : IDL.Func([Name, IDL.Text], [], []),
    'changeMetaDataInRef' : IDL.Func([IDL.Text, IDL.Text], [Result_1], []),
    'changeName' : IDL.Func(
        [TokenIdentifier, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_2],
        [],
      ),
    'confirmListing' : IDL.Func([AccountIdentifier], [Result_9], []),
    'confirmSell' : IDL.Func([AccountIdentifier], [Result_8], []),
    'declineCraft' : IDL.Func([IDL.Text], [Result_1], []),
    'declineListing' : IDL.Func([ListingId], [Result_1], []),
    'deleteAcc' : IDL.Func([TokenIdentifier], [], []),
    'deleteFromRefBook' : IDL.Func([Name], [], []),
    'deletefromCraft' : IDL.Func([IDL.Text], [], []),
    'disarm' : IDL.Func(
        [
          TokenIdentifier,
          IDL.Opt(TokenIdentifier),
          IDL.Vec(IDL.Nat8),
          CurrentEquip,
        ],
        [Result_1],
        [],
      ),
    'fillItems' : IDL.Func([], [], []),
    'getAccounts' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, UpdatedUserAccount))],
        [],
      ),
    'getCanisterMemorySize' : IDL.Func([], [CanisterMemorySize], ['query']),
    'getCharEquipFromTid' : IDL.Func(
        [TokenIdentifier],
        [IDL.Opt(CurrentEquip)],
        ['query'],
      ),
    'getCharLvl' : IDL.Func([TokenIdentifier], [IDL.Nat], ['query']),
    'getCharacters' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, CharactersMetadata))],
        [],
      ),
    'getCrafting' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, Contract))],
        ['query'],
      ),
    'getDefAttributes' : IDL.Func([IDL.Text], [IDL.Opt(IDL.Text)], ['query']),
    'getHeartBeatStatus' : IDL.Func([], [IDL.Bool], ['query']),
    'getItemStateFromTid' : IDL.Func([TokenIdentifier], [Result_1], ['query']),
    'getItemsMd' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, Item))],
        ['query'],
      ),
    'getMDFromRef' : IDL.Func([IDL.Text, TokenIdentifier], [Result_1], []),
    'getMd' : IDL.Func([TokenIdentifier], [IDL.Text], ['query']),
    'getMetaDataFromRef' : IDL.Func([IDL.Text], [Result_1], ['query']),
    'getNewAccounts' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, UserAccount))],
        [],
      ),
    'getRefBook' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(Name, ReferenceBook))],
        ['query'],
      ),
    'getSigned' : IDL.Func([TokenIdentifier], [IDL.Opt(UserName)], []),
    'getStakeAditFromAID' : IDL.Func([], [IDL.Vec(StakeAdit)], []),
    'getStakeCoalFromAID' : IDL.Func([], [IDL.Vec(StakeCoal)], []),
    'getStakeFromAID' : IDL.Func([], [IDL.Vec(Stake)], []),
    'getStakeOreFromAID' : IDL.Func([], [IDL.Vec(StakeOre)], []),
    'getStaked' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, Stake))],
        ['query'],
      ),
    'getStakedAdit' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, StakeAdit))],
        ['query'],
      ),
    'getStakedCoal' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, StakeCoal))],
        ['query'],
      ),
    'getStakedOre' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, StakeOre))],
        ['query'],
      ),
    'getTIFromRef' : IDL.Func([Name], [Result_7], ['query']),
    'getTokenInfo' : IDL.Func([Collections], [TokenInfo], ['query']),
    'getTokenInfoRare' : IDL.Func([Collections], [TokenInfoRarity], ['query']),
    'getTokenOwner' : IDL.Func([Collections], [AccountIdentifier], []),
    'getTokensRarity' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, TokenRarity))],
        [],
      ),
    'getUnsigned' : IDL.Func([TokenIdentifier], [IDL.Opt(TokenIdentifier)], []),
    'getWeaponAssetName' : IDL.Func([IDL.Nat32], [IDL.Text], ['query']),
    'getWeapons' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(TokenIdentifier, Weapons))],
        [],
      ),
    'heartbeat_disbursements' : IDL.Func([], [], []),
    'mintNft' : IDL.Func(
        [
          IDL.Text,
          AccountIdentifier,
          IDL.Text,
          IDL.Opt(IDL.Text),
          IDL.Vec(IDL.Nat8),
        ],
        [TokenIdentifier],
        [],
      ),
    'purchase' : IDL.Func([ListingId, Price], [Result_6], []),
    'registryAcc' : IDL.Func(
        [TokenIdentifier, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_2],
        [],
      ),
    'rewardLoot' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Vec(IDL.Nat8), IDL.Nat],
        [Result_5],
        [],
      ),
    'saveAllData' : IDL.Func([], [], []),
    'saveProgress' : IDL.Func([UserAccount, IDL.Vec(IDL.Nat8)], [Result_2], []),
    'setArm' : IDL.Func(
        [
          TokenIdentifier,
          IDL.Opt(TokenIdentifier),
          IDL.Vec(IDL.Nat8),
          CurrentEquip,
        ],
        [Result_1],
        [],
      ),
    'setStake' : IDL.Func([Stake], [], []),
    'setStakeAdit' : IDL.Func([StakeAdit], [], []),
    'setStakeCoal' : IDL.Func([StakeCoal], [], []),
    'setStakeOre' : IDL.Func([StakeOre], [], []),
    'setStatus' : IDL.Func([], [], []),
    'startGame' : IDL.Func(
        [TokenIdentifier, IDL.Vec(IDL.Nat8)],
        [Result_4],
        [],
      ),
    'successfulCraft' : IDL.Func([IDL.Text], [Result_1], []),
    'textToNat' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'this_balance_ic' : IDL.Func([], [Tokens__1], []),
    'this_balance_icrc' : IDL.Func([IDL.Text], [Balance__1], []),
    'transferAll' : IDL.Func([IDL.Text], [], []),
    'transferMany' : IDL.Func(
        [AccountIdentifier, IDL.Vec(TokenIdentifier), SubAccount__1],
        [Result_3],
        [],
      ),
    'transferMany_toSubacc' : IDL.Func(
        [IDL.Vec(IDL.Nat8), IDL.Vec(TokenIdentifier), SubAccount__1],
        [Result_3],
        [],
      ),
    'transferToken' : IDL.Func(
        [
          CanisterIdentifier,
          IDL.Text,
          IDL.Vec(IDL.Nat8),
          AccountIdentifier,
          IDL.Nat,
        ],
        [TransferResult],
        [],
      ),
    'transfer_icrc_1_token' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Vec(IDL.Nat8), IDL.Vec(IDL.Nat8), IDL.Nat],
        [TransferResult],
        [],
      ),
    'unStake' : IDL.Func([TokenIdentifier], [Result], []),
    'unStakeAdit' : IDL.Func([TokenIdentifier], [Result], []),
    'unStakeCoal' : IDL.Func([TokenIdentifier], [Result], []),
    'unStakeOre' : IDL.Func([TokenIdentifier], [Result], []),
    'unWrap' : IDL.Func([IDL.Vec(TokenIdentifier)], [Result], []),
    'updateCharEquip' : IDL.Func(
        [TokenIdentifier, CurrentEquip, IDL.Vec(SaveEq), IDL.Vec(IDL.Nat8)],
        [Result_2],
        [],
      ),
    'updateCharacter' : IDL.Func([TokenIdentifier, CharactersMetadata], [], []),
    'updateHashMap' : IDL.Func([], [], []),
    'updateTokenRarity' : IDL.Func([TokenIdentifier, TokenRarity], [], []),
    'updateWeapon' : IDL.Func([TokenIdentifier, Weapons], [], []),
    'useBackup' : IDL.Func([], [], []),
    'useCraft' : IDL.Func(
        [
          TokenIdentifier,
          IDL.Text,
          IDL.Vec(Price),
          IDL.Tuple(Name, Amount),
          IDL.Text,
          SubAccount,
          IDL.Nat,
        ],
        [Result_1],
        [],
      ),
    'useLoot' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Vec(IDL.Nat8), IDL.Nat],
        [TransferResult],
        [],
      ),
    'verification' : IDL.Func([], [], []),
    'wrap' : IDL.Func([IDL.Vec(TokenIdentifier)], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };