export const sets = {
  armor: [
    { name: "Royal Valor", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/GOLD_A.png" },
    { name: "Mournful Cry", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/IRON_A.png" },
    { name: "Reliable Barrel", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/BARREL_A.png" },
    { name: "Rogue`s Leather", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/SCALE_A.png" },
    { name: "The Damned", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/Damned_A.png" },
    { name: "The Holy Seal", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/HOLY_A.png" },
    { name: "Royal Valor", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/GOLD_A.png" },
  ],
  hairstyle: [
    { name: "Dfinity-Colour", img: "" },
    { name: "Gray (Short)", img: "" },
    { name: "Red (Short)", img: "" },
    { name: "Gray", img: "" },
    { name: "Red", img: "" },
  ],
  scars: [
    { name: "Double (L)", img: "" },
    { name: "Ordinary", img: "" },
    { name: "Type 2 (L)", img: "" },
    { name: "Type 1 (L)", img: "" },
    { name: "Type 2 (R) ", img: "" },
    { name: "Type 1 (R) ", img: "" },
  ],

  gloves: [
    { name: "Royal Valor", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/GOLD_G.png" },
    { name: "Mournful Cry", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/IRON_G.png" },
    { name: "Reliable Barrel", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/BARREL_G.png" },
    { name: "Rogue`s Leather", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/SCALE_G.png" },
    { name: "The Damned", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/Damned_G.png" },
    { name: "The Holy Seal", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/HOLY_G.png" },
  ],
  boots: [
    { name: "Royal Valor", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/GOLD_B.png" },
    { name: "Mournful Cry", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/IRON_B.png" },
    { name: "Reliable Barrel", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/BARREL_B.png" },
    { name: "Rogue`s Leather", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/SCALE_B.png" },
    { name: "The Damned", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/Damned_B.png" },
    { name: "The Holy Seal", img: "https://ubsyh-7iaaa-aaaan-qcxaa-cai.raw.ic0.app/asset/HOLY_B.png" },
  ],
};
