import React from "react";
import { ToggleMenu } from "../../UI/ToggleMenu/ToggleMenu";
import classes from "./MenuBar.module.css";
import menuDecor from "./menuDecor.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export const MenuBar = ({ children, clicked, setClicked, curLink,address,setAddress,setConfirmLogOut }) => {
  return (
    <div className={classes.root}>
      <div>
        <img src={menuDecor} className={classes.left} alt="" />
        <div className={classes.contentBlock}>
          <div className={classes.actions}>{children}</div>
          <ToggleMenu setConfirmLogOut={setConfirmLogOut} address={address} setAddress={setAddress} clicked={clicked} setClicked={setClicked} curLink={curLink}/>
          {/* <div className={classes.wallet}>
          </div> */}
        </div>
        <img src={menuDecor} className={classes.right} alt="" />
      </div>
    </div>
  );
};
