import React from "react";
import classes from "./SearchPanel.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export const SearchPanel = ({ color, searchTerm, setSearchTerm, handleChange, title = "", icon }) => {
  return (
    <div className={classes.root}>
      {icon ? <SearchIcon style={{ color: color }} fontSize="large" className="searchIcon" /> : null}
      {/* <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      /> */}
      <TextField
        id="outlined-basic"
        fullWidth
        value={searchTerm}
        onChange={handleChange}
        label={title}
        variant="outlined"
        style={{
          padding: "0",
        }}
        InputProps={{
          style: {
            color: "white",
            padding: "0",
            backgroundColor: "rgba(59,59,59,0.5)",
          },
          endAdornment: searchTerm && (
            <IconButton aria-label="toggle password visibility" onClick={() => setSearchTerm("")}>
              <ClearIcon sx={{ color: "rgba(236, 131, 46, 1)" }} />
            </IconButton>
          ),
        }}
        InputLabelProps={{
          style: {
            padding: "0",
            color: "#c7c7c7",
          },
        }}
      />
    </div>
  );
};
