import React from "react";
import { useState } from "react";
import classes from "./DragAndDrop.module.css";

export const DragAndDrop = ({ onDropHandler }) => {
  const [drag, setDrag] = useState(false);

  function dragStartHandler(e) {
    e.preventDefault();
    setDrag(true);
  }
  function dragLeaveHandler(e) {
    e.preventDefault();
    setDrag(false);
  }

  return (
    <div className={classes.root}>
      {drag ? (
        <div
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e)}
          className={classes.dropAria}
        >
          <p>Drop the image to upload</p>
        </div>
      ) : (
        <div onDragStart={(e) => dragStartHandler(e)} onDragLeave={(e) => dragLeaveHandler(e)} onDragOver={(e) => dragStartHandler(e)}>
          <p>Drop the image to upload</p>{" "}
        </div>
      )}
    </div>
  );
};
