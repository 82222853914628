import React from "react";
import classes from "./TransactionsAccordion.module.css";
import {
  Paper,
  AccordionDetails,
  AccordionSummary,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  Table,
  TablePagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clipboardCopy, getParamOnPage } from "../../../utils/utils";
import loader from "./../../../media/loader.gif";
import { useNavigate } from "react-router-dom";

function sortInNestTokens(a, b) {
  if (a[1].time) {
    return a[1].time > b[1].time ? -1 : b[1].time > a[1].time ? 1 : 0;
  }
  if (a[1].timestamp) {
    return a[1].timestamp > b[1].timestamp ? -1 : b[1].timestamp > a[1].timestamp ? 1 : 0;
  }
}

function sortInNestNfts(a, b) {
  return a.time > b.time ? -1 : b.time > a.time ? 1 : 0;
}

export const TransactionsAccordion = ({
  primary_color,
  secondary_color,
  transactions,
  setCopied,
  type,
  totalRows,
  setRowsPerPage,
  page,
  rowsPerPage,
  collectionName,
}) => {
  const lastTransactionIndex = (page + 1) * rowsPerPage;
  const firstTransactionIndex = lastTransactionIndex - rowsPerPage;
  let navigate = useNavigate();
  let rows;

  let ledgerCanister = getParamOnPage("canister");

  // if (type !== "icrcTokens") {
  //   totalRows = 0;
  // }

  let currentTransactions;

  let sorted;

  if (transactions !== "loading") {
    if (JSON.parse(transactions)) {
      if (type === "token") sorted = JSON.parse(transactions).sort(sortInNestTokens);
      if (type === "nfts") sorted = JSON.parse(transactions).sort(sortInNestNfts);
      if (type === "icrcToken" && transactions !== "loading") sorted = JSON.parse(transactions);
    }
  }

  if (sorted) {
    if (type === "icrcToken") {
      currentTransactions = sorted;
    } else {
      currentTransactions = sorted.slice(firstTransactionIndex, lastTransactionIndex);
      totalRows = JSON.parse(transactions).length;
    }
  }

  async function handleSubmitTokenInfo(name, id, ledgerCanister, type, newPage) {
    navigate("?id=" + id + "&?canister=" + ledgerCanister + "&?type=" + type + "&?page=" + newPage);
  }

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    // page = newPage;
    handleSubmitTokenInfo(collectionName, id, ledgerCanister, type, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    // page = 0;
    navigate("?id=" + id + "&?canister=" + ledgerCanister + "&?type=" + type + "&?page=" + 0);
  };

  let id = 0;

  let sw = "1110px";
  if (page === "index") {
    if (window.innerWidth >= 768 && window.innerWidth < 1110) {
      sw = "760px";
    }
    if (window.innerWidth >= 540 && window.innerWidth < 768) {
      sw = "540px";
    }
    if (window.innerWidth < 540) {
      sw = "340px ";
    }
  }
  if (currentTransactions) {
    if (type === "token") {
      rows = currentTransactions.map((row) => (
        <TableRow key={id++} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {page === "index" ? (
            <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?type=thumbnail&tokenid=" + row.token} alt="tokenImg" />
            </TableCell>
          ) : null}
          <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
            {row[0]}
          </TableCell>
          <TableCell
            className="address"
            onClick={() => {
              clipboardCopy(row[1].from?.address);
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
            style={{ color: "#fafafa" }}
            component="th"
            scope="row"
            align="center"
          >
            {row[1].from?.address.substr(0, 6) + "..." + row[1].from?.address.substr(58, 64)}
          </TableCell>
          <TableCell
            className="address"
            onClick={() => {
              clipboardCopy(row[1].to?.address);
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
            style={{ color: "#fafafa" }}
            align="center"
          >
            {row[1].to?.address.substr(0, 6) + "..." + row[1].to?.address.substr(58, 64)}
          </TableCell>
          <TableCell align="center" style={{ color: "#fafafa" }} component="th" scope="row">
            {row[1].amount}
          </TableCell>
          <TableCell style={{ color: "#fafafa" }} align="center">
            {new Date(row[1].time / 1_000_000).toLocaleDateString() + " " + new Date(row[1].time / 1_000_000).toLocaleTimeString()}
          </TableCell>
        </TableRow>
      ));
    }
    if (type === "icrcToken") {
      if (transactions === "loading") {
      } else {
        rows = currentTransactions.map((row) => (
          <TableRow key={id++} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {/* {page === "index" ? (
              <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
                <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?type=thumbnail&tokenid=" + row.token} alt="tokenImg" />
              </TableCell>
            ) : null}
            <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
              {row[0]}
            </TableCell> */}
            <TableCell
              className="address"
              onClick={() => {
                clipboardCopy(row.from?.address);
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
              style={{ color: "#fafafa" }}
              component="th"
              scope="row"
              align="center"
            >
              {row.args.from?.address.substr(0, 6) + "..." + row.args.from?.address.substr(58, 64)}
            </TableCell>
            <TableCell
              className="address"
              onClick={() => {
                clipboardCopy(row.args.to?.address);
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
              style={{ color: "#fafafa" }}
              align="center"
            >
              {row.args.to?.address.substr(0, 6) + "..." + row.args.to?.address.substr(58, 64)}
            </TableCell>
            <TableCell align="center" style={{ color: "#fafafa" }} component="th" scope="row">
              {row.args.amount}
            </TableCell>
            <TableCell align="center" style={{ color: "#fafafa" }} component="th" scope="row">
              {/* {row[1].kind} */}
              {Object.keys(row.kind)}
            </TableCell>
            <TableCell style={{ color: "#fafafa" }} align="center">
              {new Date(row.timestamp / 1_000_000).toLocaleDateString() + " " + new Date(row.timestamp / 1_000_000).toLocaleTimeString()}
            </TableCell>
          </TableRow>
        ));
      }
    }

    if (type === "nfts") {
      rows = currentTransactions.map((row) => (
        <TableRow key={id++} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {page === "index" ? (
            <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?type=thumbnail&tokenid=" + row.token} alt="tokenImg" />
            </TableCell>
          ) : null}
          <TableCell
            className="address"
            onClick={() => {
              clipboardCopy(row.from?.address);
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
            style={{ color: "#fafafa" }}
            component="th"
            scope="row"
            align="center"
          >
            {row.from?.address.substr(0, 6) + "..." + row.from?.address.substr(58, 64)}
          </TableCell>
          <TableCell
            className="address"
            onClick={() => {
              clipboardCopy(row.to?.address);
              setCopied(true);
              setTimeout(() => setCopied(false), 1000);
            }}
            style={{ color: "#fafafa" }}
            align="center"
          >
            {row.to?.address.substr(0, 6) + "..." + row.to?.address.substr(58, 64)}
          </TableCell>
          <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
            {row.amount}
          </TableCell>
          <TableCell style={{ color: "#fafafa" }} align="center">
            {new Date(row.time / 1_000_000).toLocaleDateString() + " " + new Date(row.time / 1_000_000).toLocaleTimeString()}
          </TableCell>
        </TableRow>
      ));
    }
  }
  if (type === "icrcToken") {
    return (
      <div className="accordion">
        <Accordion
          style={{
            width: page === "index" ? sw : "auto",
            marginTop: "15px",
            background: "linear-gradient(136.25deg, rgba(67, 67, 67, 0.61) 5.16%, rgba(88, 87, 87, 0.87) 96.94%)",
            boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.5), inset -7px -3px 12px rgba(255, 255, 255, 0.15), inset 7px 3px 12px rgba(0, 0, 0, 0.45)",
            color: "#c7c7c7",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: primary_color }} />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography style={{ fontFamily: "Comfortaa", fontStyle: "normal", fontWeight: 600, fontSize: "16px", lineHeight: "140%" }}>
              Transactions
            </Typography>
          </AccordionSummary>
          {transactions !== "[]" ? (
            transactions !== "loading" ? (
              <AccordionDetails>
                <div className="accordionPagination">
                  <TablePagination
                    component="div"
                    count={totalRows}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={<span>Rows:</span>}
                    labelDisplayedRows={({ page }) => {
                      return `Page: ${page + 1}`;
                    }}
                    showLastButton
                    showFirstButton
                  />
                </div>
                <TableContainer style={{ backgroundColor: secondary_color }} sx={{ width: "auto" }} variant="outlined" component={Paper}>
                  <Table sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {page === "index" ? (
                          <TableCell style={{ color: "#fafafa" }} align="center">
                            Thumbnail
                          </TableCell>
                        ) : null}
                        {/* {type === "icrcToken" ? (
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          id
                        </TableCell>
                      ) : null} */}

                        <TableCell style={{ color: "#fafafa" }} align="center">
                          From
                        </TableCell>
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          to
                        </TableCell>
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          Amount
                        </TableCell>
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          Kind
                        </TableCell>
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            ) : rowsPerPage === 5 ? (
              <div className={classes.loaderContainer5perPage}>
                <img src={loader} alt="loading" />
              </div>
            ) : rowsPerPage === 10 ? (
              <div className={classes.loaderContainer10perPage}>
                <img src={loader} alt="loading" />
              </div>
            ) : (
              <div className={classes.loaderContainer25perPage}>
                <img src={loader} alt="loading" />
              </div>
            )
          ) : (
            <AccordionDetails>
              <Typography style={{ textTransform: "none" }}>No information on transactions</Typography>
            </AccordionDetails>
          )}
        </Accordion>
      </div>
    );
  } else {
    return (
      <div className="accordion">
        <Accordion
          style={{
            width: page === "index" ? sw : "auto",
            marginTop: "15px",
            background: "linear-gradient(136.25deg, rgba(67, 67, 67, 0.61) 5.16%, rgba(88, 87, 87, 0.87) 96.94%)",
            boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.5), inset -7px -3px 12px rgba(255, 255, 255, 0.15), inset 7px 3px 12px rgba(0, 0, 0, 0.45)",
            color: "#c7c7c7",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: primary_color }} />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography style={{ fontFamily: "Comfortaa", fontStyle: "normal", fontWeight: 600, fontSize: "16px", lineHeight: "140%" }}>
              Transactions
            </Typography>
          </AccordionSummary>
          {transactions !== "[]" ? (
            <AccordionDetails>
              <div className="accordionPagination">
                <TablePagination
                  component="div"
                  count={totalRows}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={<span>Rows:</span>}
                  labelDisplayedRows={({ page }) => {
                    return `Page: ${page + 1}`;
                  }}
                  showLastButton
                  showFirstButton
                />
              </div>
              <TableContainer style={{ backgroundColor: secondary_color }} sx={{ width: "auto" }} variant="outlined" component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {page === "index" ? (
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          Thumbnail
                        </TableCell>
                      ) : null}
                      {type === "token" ? (
                        <TableCell style={{ color: "#fafafa" }} align="center">
                          id
                        </TableCell>
                      ) : null}

                      <TableCell style={{ color: "#fafafa" }} align="center">
                        From
                      </TableCell>
                      <TableCell style={{ color: "#fafafa" }} align="center">
                        to
                      </TableCell>
                      <TableCell style={{ color: "#fafafa" }} align="center">
                        Amount
                      </TableCell>
                      <TableCell style={{ color: "#fafafa" }} align="center">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{rows}</TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          ) : (
            <AccordionDetails>
              <Typography style={{ textTransform: "none" }}>No information on transactions</Typography>
            </AccordionDetails>
          )}
        </Accordion>
      </div>
    );
  }
};
