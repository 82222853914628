import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cornerRight from "./../../../media/cornerDecorRight.png";
import cornerLeft from "./../../../media/cornerDecorLeft.png";

export const ProjectItem = ({ id, name, description, preview, secondary_color }) => {
  const [fontSize, setFontSize] = useState({ title: "24px", description: 15 });

  useEffect(() => {
    if (window.innerWidth < 540) {
      setFontSize({ title: "15px", description: 10 });
    }

  }, [window.innerWidth]);

  let navigate = useNavigate();

  async function handleSubmitProjects(name, id) {
    navigate(name + "?id=" + id);
  }
  if (description?.toLowerCase() === "worold of eimolad") description = "World of Eimolad"; //TODO: delete later

  return (
    <Grid item key={id}>
      <div className="gridItem">
        <img className={"gridItem__cornerDecorLeft"} src={cornerLeft} alt="Corner Decor" />
        <img className={"gridItem__cornerDecorRight"} src={cornerRight} alt="Corner Decor" />
        <Card
          onClick={() => {
            handleSubmitProjects(name, id);
          }}
          className="card collectionCard"
          style={{ backgroundColor: secondary_color }}
          key={id}
        >
          <CardActionArea>
            <CardMedia component="img" height="150" image={"https://xpwrw-vyaaa-aaaan-qaojq-cai.raw.ic0.app/?asset=" + preview} alt="img" />
            <CardContent>
              <Typography style={{ paddingLeft: "13px", fontSize: fontSize.title, color: "rgba(236, 131, 46, 1)" }} gutterBottom>
                {name}
              </Typography>
              <Typography fontSize={fontSize.description}>{description}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
};
