import React from "react";
import classes from "./ParamsBlock.module.css";
import { ParamItem } from "../../UI/ParamItem/ParamItem";
import { Skeleton, Typography } from "@mui/material";
import { sets } from "../../../utils/sets/armor_sets";
import {
  showAccuracy,
  showAttributeWithLvl,
  showEvasion,
  showHpRecovery,
  showMagicAttack,
  showManaPoints,
  showMaxHp,
  showMpRecovery,
  showPhysicalAttack,
  showPhysicalAttackSpeed,
  showStatusResistance,
} from "../../../utils/attributeMechanics";

const changeAccuracy = (collection, value) => {
  if (collection === "Dwarves") {
    return 70 + value + "%";
  }
  return value;
};

const changeEvaision = (collection, value) => {
  if (collection === "Dwarves") {
    return 0 + value + "%";
  }
  return value;
};
function sortObject(obj) {
  let sortedObject = {};
  sortedObject["type"] = obj["type"];
  delete obj["type"];
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      sortedObject[key] = obj[key];
    });
  return sortedObject;
}

export const ParamsBlock = ({ metadata, type, collectionName, setCopied, copied, attributes, baseAttributes }) => {
  let id = 0;
  let metadataArray = [];
  let paramElements = [];
  let attributesArray = [];
  let attributesElements = [];
  let sortedWeaponMetadata = {};

  if (metadata) {
    // console.log(metadata)
    if (collectionName === "Weapons") {
      sortedWeaponMetadata = sortObject(metadata);
      if (sortedWeaponMetadata.type === undefined) {
        sortedWeaponMetadata.type = metadata.weaponType;
      }
      for (let i in sortedWeaponMetadata) {
        // console.log(sortedWeaponMetadata);
        let data = {};
        data.name = i;
        data.value = sortedWeaponMetadata[i];
        if (data.name === "weaponType") continue;
        if (data.name === "type" && attributes) {
          JSON.parse(attributes)["type"] === 1 ? (data.value = "one-handed") : (data.value = "two-handed");
          // continue;
        }
        if (data.name === "ledger canister") continue;
        if (data.name === "model canister") continue;
        else {
          if (data.name === "tid") {
            data.name = "token id";
            data.advanced = true;
            data.metadata = {
              ["ledger canister"]: sortedWeaponMetadata["ledger canister"],
              ["model canister"]: sortedWeaponMetadata["model canister"],
            };
            metadataArray.push(data);
          } else {
            metadataArray.push(data);
          }
        }
      }
      paramElements = metadataArray.map((md) => (
        <ParamItem
          setCopied={setCopied}
          copied={copied}
          key={id++}
          name={md.name}
          value={String(md.value)}
          advanced={md.advanced}
          metadata={md.metadata}
        />
      ));
      // console.log(metadata)
    }
    if (collectionName === "Weapons") {
      sortedWeaponMetadata = sortObject(metadata);
      if (sortedWeaponMetadata.type === undefined) {
        sortedWeaponMetadata.type = metadata.weaponType;
      }
      for (let i in sortedWeaponMetadata) {
        // console.log(sortedWeaponMetadata);
        let data = {};
        data.name = i;
        data.value = sortedWeaponMetadata[i];
        if (data.name === "weaponType") continue;
        if (data.name === "type") {
          // console.log(JSON.parse(attributes));
          data.value === 1 ? (data.value = "one-handed") : (data.value = "two-handed");
          // continue;
        }
        if (data.name === "ledger canister") continue;
        if (data.name === "model canister") continue;
        else {
          if (data.name === "tid") {
            data.name = "token id";
            data.advanced = true;
            data.metadata = {
              ["ledger canister"]: sortedWeaponMetadata["ledger canister"],
              ["model canister"]: sortedWeaponMetadata["model canister"],
            };
            metadataArray.push(data);
          } else {
            metadataArray.push(data);
          }
        }
      }
      // const metadataSet = new Set(metadataArray);
      // paramElements = metadataSet.map((md) => (
      // <ParamItem
      // setCopied={setCopied}
      // copied={copied}
      // key={id++}
      // name={md.name}
      // value={String(md.value)}
      // advanced={md.advanced}
      // metadata={md.metadata}
      // />
      // ));
      // console.log(metadataSet);
      // console.log(metadata)
    } else if (collectionName === "Dwarves") {
      for (let i in metadata) {
        let data = {};
        data.name = i;
        data.value = metadata[i];

        if (data.name === "ledger canister") continue;
        if (data.name === "model canister") continue;
        else {
          if (data.name === "tid") {
            data.name = "token id";
            data.advanced = true;
            data.metadata = {
              ["ledger canister"]: metadata["ledger canister"],
              ["model canister"]: metadata["model canister"],
            };
            metadataArray.push(data);
          } else if (data.name === "equipment") {
            data.advanced = true;
            data.metadata = {
              ["armor"]: sets.armor[data.value[0]]?.name ? sets.armor[data.value[0]].name : "FAM",
              ["gloves"]: sets.gloves[data.value[3]]?.name ? sets.gloves[data.value[3]].name : "FAM",
              ["boots"]: sets.boots[data.value[4]]?.name ? sets.boots[data.value[4]].name : "FAM",
              ["hairstyle"]: sets.hairstyle[data.value[1]]?.name ? sets.hairstyle[data.value[1]].name : "FAM",
              ["scars"]: sets.scars[data.value[2]]?.name ? sets.scars[data.value[2]].name : "FAM",
            };
            metadataArray.push(data);
          } else {
            metadataArray.push(data);
          }
        }
      }
      paramElements = metadataArray.map((md) => (
        <ParamItem
          setCopied={setCopied}
          copied={copied}
          key={id++}
          name={md.name}
          value={String(md.value)}
          advanced={md.advanced}
          metadata={md.metadata}
        />
      ));
    } else if (collectionName === "Non-grade weapons" || collectionName === "non-grade armor") {
      for (let i in metadata) {
        let data = {};
        data.name = i;
        data.value = metadata[i];
        if (data.name === "type") {
          // console.log(JSON.parse(attributes));
          if (collectionName === "non-grade armor") {
            continue;
          } else {
            data.value === 1 ? (data.value = "one-handed") : (data.value = "two-handed");
          }
          // continue;
        }
        if (data.name === "ledger canister") continue;
        if (data.name === "token id") {
          data.advanced = true;
          data.metadata = {
            ["ledger canister"]: metadata["ledger canister"],
          };
        }
        metadataArray.push(data);
      }

      paramElements = metadataArray.map((md) => (
        <ParamItem
          setCopied={setCopied}
          copied={copied}
          key={id++}
          name={md.name}
          value={String(md.value)}
          advanced={md.advanced}
          metadata={md.metadata}
        />
      ));
    } else if (collectionName === "Licenses" || collectionName === "Astronauts") {
      for (let i in metadata) {
        let data = {};
        data.name = i;
        data.value = metadata[i];
        metadataArray.push(data);
      }

      paramElements = metadataArray.map((md) => (
        <ParamItem
          setCopied={setCopied}
          copied={copied}
          key={id++}
          name={md.name}
          value={String(md.value)}
          advanced={md.advanced}
          metadata={md.metadata}
        />
      ));
    }
    if (type === "icrcToken") {
      for (let i in metadata) {
        // console.log(sortedWeaponMetadata);
        let data = {};
        data.name = i;
        data.value = metadata[i];
        metadataArray.push(data);
      }
      paramElements = metadataArray.map((md) => (
        <ParamItem
          setCopied={setCopied}
          copied={copied}
          key={id++}
          name={md.name}
          value={String(md.value)}
          advanced={md.advanced}
          metadata={md.metadata}
        />
      ));
      // console.log(metadata)
    }
  }

  if (attributes !== null && attributes !== undefined) {
    if (attributes.length === 0) {
      let data = {};
      data.name = "Character not registered";
      data.value = "";
      attributesArray.push(data);
      attributesElements = attributesArray.map((md) => (
        <ParamItem setCopied={setCopied} copied={copied} key={1} name={md.name} value={String(md.value)} />
      ));
    } else {
      if (
        collectionName === "Dwarves" &&
        attributes &&
        baseAttributes
        // && attributes !== "Character not registered"
      ) {
        // for (let i in JSON.parse(attributes)) {
        //   // if (i === "level" || i === "strength" || i === "dexterity" || i === "intelligence" || i === "move_speed") {
        //   let data = {};
        //   data.name = i.replace("_", " ").replace("_", " ");
        //   data.value = JSON.parse(attributes)[i];
        //   if (data.name === "level") {
        //     data.advanced = true;
        //     data.metadata = {
        //       experience: JSON.parse(attributes).experience,
        //     };
        //     attributesArray.push(data);
        //   }
        //   if (data.name === "strength") {
        //     data.advanced = true;
        //     data.metadata = {
        //       hp: JSON.parse(attributes).hp,
        //       ["physical attack"]: JSON.parse(attributes).attack,
        //       ["status resist"]: JSON.parse(attributes).st_resist,
        //       ["hp regen"]: JSON.parse(attributes).hp_regen,
        //     };
        //     attributesArray.push(data);
        //   }
        //   if (data.name === "dexterity") {
        //     data.advanced = true;
        //     data.metadata = {
        //       ["attack speed"]: JSON.parse(attributes).attack_speed,
        //       ["evasion"]: changeEvaision("Dwarves", JSON.parse(attributes).evasion),
        //       ["accuracy"]: changeAccuracy("Dwarves", JSON.parse(attributes).accuracy),
        //     };
        //     attributesArray.push(data);
        //   }
        //   if (data.name === "intelligence") {
        //     data.advanced = true;
        //     data.metadata = {
        //       ["mana pool"]: JSON.parse(attributes).mp,
        //       ["magic attack"]: JSON.parse(attributes).m_attack,
        //       ["mp regen"]: JSON.parse(attributes).mp_regen,
        //     };
        //     attributesArray.push(data);
        //   }
        //   if (data.name === "move speed") {
        //     data.advanced = false;
        //     attributesArray.push(data);
        //   }
        //   //}
        // }

        let strength = showAttributeWithLvl(attributes.lvl, baseAttributes.strength, baseAttributes.strengthRate);
        let dexterity = showAttributeWithLvl(attributes.lvl, baseAttributes.dexterity, baseAttributes.dexterityRate);
        let intelligence = showAttributeWithLvl(attributes.lvl, baseAttributes.intelligence, baseAttributes.intelligenceRate);

        let lvlData = {
          name: "level",
          value: attributes.lvl == 0 ? "Character not registered" : attributes.lvl,
        };
        attributesArray.push(lvlData);

        let strengthData = {
          name: "strength",
          advanced: true,
          value: strength,
          metadata: {
            hp: showMaxHp(strength),
            ["physical attack"]: showPhysicalAttack(strength, dexterity),
            ["status resist"]: showStatusResistance(strength) + "%",
            ["hp regen"]: showHpRecovery(strength),
          },
        };
        attributesArray.push(strengthData);

        let dexterityData = {
          name: "dexterity",
          advanced: true,
          value: dexterity,
          metadata: {
            ["attack speed"]: showPhysicalAttackSpeed(baseAttributes.initialPhysicalAttackSpeed, dexterity),
            ["evasion"]: showEvasion(dexterity) + "%",
            ["accuracy"]: showAccuracy(dexterity) + "%",
          },
        };
        attributesArray.push(dexterityData);

        let intelligenceData = {
          name: "intelligence",
          advanced: true,
          value: intelligence,
          metadata: {
            ["mana pool"]: showManaPoints(intelligence),
            ["magic attack"]: showMagicAttack(intelligence),
            ["mp regen"]: showMpRecovery(intelligence),
          },
        };
        attributesArray.push(intelligenceData);

        attributesArray.push({ name: "move speed", value: baseAttributes.moveSpeed });

        attributesElements = attributesArray.map((md) => (
          <ParamItem
            setCopied={setCopied}
            copied={copied}
            key={id++}
            name={md.name}
            value={String(md.value)}
            advanced={md.advanced}
            metadata={md.metadata}
          />
        ));
      }
      if (collectionName === "Weapons") {
        if (attributes !== null && attributes !== "Character not registered") {
          attributesArray.push({ name: "range", value: "melee" });
          for (let i in JSON.parse(attributes)) {
            // if (i === "level" || i === "strength" || i === "dexterity" || i === "intelligence" || i === "move_speed") {
            let data = {};
            data.name = i.replace("_", " ").replace("_", " ");
            data.value = JSON.parse(attributes)[i];
            if (data.name === "attack sp") data.name = "Attack speed";
            // if (data.name === "crit d") data.name = "critical damage";
            if (data.name === "p attack") data.name = "physical attack";
            if (data.name === "m attack") data.name = "magic attack";
            if (data.name === "type") {
              // data.name = "range";
              // data.value === 1 ? (data.value = "one-handed") : (data.value = "two-handed");
              continue;
            }

            if (data.name === "adited") continue;
            if (data.name === "crit d") {
              data.advanced = true;
              data.name = "Bonuses";
              data.value = "";
              data.metadata = {
                ["critical damage"]: JSON.parse(attributes).crit_d,
                ["adited"]: JSON.parse(attributes).adited,
              };
            }

            attributesArray.push(data);
          }
          attributesElements = attributesArray.map((md) => (
            <ParamItem
              setCopied={setCopied}
              copied={copied}
              key={id++}
              name={md.name}
              value={String(md.value)}
              advanced={md.advanced}
              metadata={md.metadata}
            />
          ));
        }
      }

      if (collectionName === "Non-grade weapons" || collectionName === "non-grade armor") {
        if (attributes !== null && attributes !== "Character not registered") {
          for (let i in JSON.parse(attributes)) {
            let data = {};
            data.name = i.replace("_", " ").replace("_", " ");
            data.value = JSON.parse(attributes)[i];
            if (data.name === "range") data.value = data.value === 0 ? "melee" : data.value;
            if (data.name === "p attack") data.name = "physical attack";
            if (data.name === "m attack") data.name = "magic attack";
            if (data.name === "m resist") data.name = "magic resist";

            if (data.name === "adited") continue;

            if (data.name === "type") {
              // data.name = "range";
              // data.value === 1 ? (data.value = "one-handed") : (data.value = "two-handed");
              continue;
            }

            if (data.name === "critical damage") {
              data.advanced = true;
              data.name = "Bonuses";
              data.value = "";
              data.metadata = {
                ["critical damage"]: JSON.parse(attributes).critical_damage,
                ["adited"]: JSON.parse(attributes).adited,
              };
            }
            attributesArray.push(data);
          }
          // attributesArray.push({advanced:true,name:'123',value:''})
          attributesElements = attributesArray.map((md) => (
            <ParamItem
              setCopied={setCopied}
              copied={copied}
              key={id++}
              name={md.name}
              value={String(md.value)}
              advanced={md.advanced}
              metadata={md.metadata}
            />
          ));
        }
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.nickname}>
        <b>PARAMETERS</b>
      </div>
      <div className={classes.params}>
        <div className={classes.general}>
          <Typography component="h2">General</Typography>
          {paramElements.length !== 0 ? (
            type === "icrcToken" ? (
              <div className={classes.icrc_elements}> {paramElements}</div>
            ) : (
              paramElements
            )
          ) : (
            <div className={classes.icrc_elements}>
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
              <Skeleton animation="wave" height={"50px"} width={"280px"} />
            </div>
          )}
        </div>
        {type === "nfts" && collectionName !== "Licenses" && collectionName !== "Astronauts" ? (
          <div className={classes.combat}>
            <Typography component="h2">Combat</Typography>
            {attributesElements.length !== 0 ? (
              <>{attributesElements}</>
            ) : (
              <>
                <Skeleton animation="wave" height={"50px"} width={"280px"} />
                <Skeleton animation="wave" height={"50px"} width={"280px"} />
                <Skeleton animation="wave" height={"50px"} width={"280px"} />
                <Skeleton animation="wave" height={"50px"} width={"280px"} />
                <Skeleton animation="wave" height={"50px"} width={"280px"} />
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
