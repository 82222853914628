import React, { useState } from "react";
import classes from "./Transactions.module.css";
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Table } from "@mui/material";
import { clipboardCopy, validatePrincipal } from "../../../utils/utils";
import loader from "./../../../media/loader.gif";
import TablePagination from "@mui/material/TablePagination";

function sortInNest(a, b) {
  return a.time > b.time ? -1 : b.time > a.time ? 1 : 0;
}

export const Transactions = ({ secondary_color, primary_color, transactions, setCopied, copied }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const lastTransactionIndex = (page+1) * rowsPerPage;
  const firstTransactionIndex = lastTransactionIndex - rowsPerPage;

  let totalRows = 0;
  let currentTransactions;

  let sorted;
  if (JSON.parse(transactions)) sorted = JSON.parse(transactions).sort(sortInNest);


  if (sorted) {
    currentTransactions = sorted.slice(firstTransactionIndex, lastTransactionIndex);
    totalRows = JSON.parse(transactions).length;
  }
  
  const handleChangePage = (event, newPage) => {
    // if (newPage !== 0)
     setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let pageName = "index";
  let rows;
  let id = 0;

  let sw = "1110px";
  if (pageName === "index") {
    if (window.innerWidth >= 768 && window.innerWidth < 1110) {
      sw = "760px";
    }
    if (window.innerWidth >= 540 && window.innerWidth < 768) {
      sw = "540px";
    }
    if (window.innerWidth < 540) {
      sw = "340px ";
    }
  }

  

  if (sorted) {
    rows = currentTransactions.map((row) => (
      <TableRow key={id++} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {pageName === "index" ? (
          <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
            {validatePrincipal(row.token) ? (
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?type=thumbnail&tokenid=" + row.token} alt="tokenImg" />
            ) : (
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?asset=0"} alt="tokenImg" />
            )}
          </TableCell>
        ) : null}
        <TableCell
          className="address"
          onClick={() => {
            clipboardCopy(row?.token);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          }}
          style={{ color: "#fafafa" }}
          component="th"
          scope="row"
          align="center"
        >
          {validatePrincipal(row?.token) ? (row?.token?.substr(0, 6) + "..." + row?.token?.substr(37, 43)) : (row?.token)}
        </TableCell>

        <TableCell
          className="address"
          onClick={() => {
            clipboardCopy(row.from?.address);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          }}
          style={{ color: "#fafafa" }}
          component="th"
          scope="row"
          align="center"
        >
          {row.from?.address.substr(0, 6) + "..." + row.to?.address.substr(58, 64)}
        </TableCell>
        <TableCell
          className="address"
          onClick={() => {
            clipboardCopy(row.to?.address);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          }}
          style={{ color: "#fafafa" }}
          component="th"
          scope="row"
          align="center"
        >
          {row.to?.address.substr(0, 6) + "..." + row.to?.address.substr(58, 64)}
        </TableCell>
        <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
          {row.amount}
        </TableCell>
        <TableCell style={{ color: "#fafafa" }} align="center">
          {new Date(row.time / 1_000_000).toLocaleDateString() + " " + new Date(row.time / 1_000_000).toLocaleTimeString()}
        </TableCell>
      </TableRow>
    ));
  } else {
    return (
      <div className="loader">
        <img src={loader} alt="loading" />
      </div>
    );
  }
  return (
    <div>
      {transactions !== "[]" ? (
        <>
          <div className="tablePagination">
            <TablePagination
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={<span>Rows:</span>}
              labelDisplayedRows={({ page }) => {
                return `Page: ${page+1}`;
              }}
              showLastButton
              showFirstButton
            />
          </div>

          <TableContainer style={{ backgroundColor: secondary_color }} sx={{ width: sw, marginTop: "15px" }} variant="outlined" component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {pageName === "index" ? (
                    <TableCell style={{ color: "#fafafa" }} align="center">
                      Thumbnail
                    </TableCell>
                  ) : null}
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    Token id
                  </TableCell>
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    From
                  </TableCell>
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    To
                  </TableCell>
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    Amount
                  </TableCell>
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography style={{ textTransform: "none" }}>No information on transactions</Typography>
      )}
    </div>
  );
};
