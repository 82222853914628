import React, { useState } from "react";
import classes from "./TransactionsTable.module.css";
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Table } from "@mui/material";
import { clipboardCopy, validatePrincipal } from "../../../utils/utils";

export const TransactionsTable = ({ page, primary_color, secondary_color, transactions }) => {
  const [copied, setCopied] = useState(false);

  let rows;
  let id = 0;

  let sw = "1110px";
  if (page === "index") {
    if (window.innerWidth >= 768 && window.innerWidth < 1110) {
      sw = "760px";
    }
    if (window.innerWidth >= 540 && window.innerWidth < 768) {
      sw = "540px";
    }
    if (window.innerWidth < 540) {
      sw = "340px ";
    }
  }
  // console.log(JSON.parse(transactions));
  if (transactions) {
    rows = JSON.parse(transactions).map((row) => (
      <TableRow key={id++} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {page === "index" ? (
          <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
            {validatePrincipal(row.token) ? (
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?type=thumbnail&tokenid=" + row.token} alt="tokenImg" />
            ) : (
              <img style={{ width: "100px" }} src={"https://" + row.canister + ".raw.ic0.app/?asset=0"} alt="tokenImg" />
            )}
          </TableCell>
        ) : null}
        <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
          {id}
        </TableCell>
        <TableCell
          className="address"
          onClick={() => {
            clipboardCopy(row.from?.address);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          }}
          style={{ color: "#fafafa" }}
          component="th"
          scope="row"
          align="center"
        >
          {copied ? <div>Copied</div> : null}
          {row.from?.address.substr(0, 6) + "..." + row.to?.address.substr(58, 64)}
        </TableCell>
        <TableCell
          className="address"
          onClick={() => {
            clipboardCopy(row.to?.address);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          }}
          style={{ color: "#fafafa" }}
          component="th"
          scope="row"
          align="center"
        >
          {/* {row[1].to?.address} */}
          {copied ? <div>Copied</div> : null}
          {row.to?.address.substr(0, 6) + "..." + row.to?.address.substr(58, 64)}
        </TableCell>
        <TableCell style={{ color: "#fafafa" }} component="th" scope="row" align="center">
          {row.amount}
        </TableCell>
        <TableCell style={{ color: "#fafafa" }} align="center">
          {new Date(row.time / 1_000_000).toLocaleDateString() + " " + new Date(row.time / 1_000_000).toLocaleTimeString()}
        </TableCell>
      </TableRow>
    ));
  }
  return (
    <div className="accordion">
      {transactions !== "[]" ? (
        <TableContainer style={{ backgroundColor: secondary_color }} sx={{ width: sw, marginTop: "15px" }} variant="outlined" component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {page === "index" ? (
                  <TableCell style={{ color: "#fafafa" }} align="center">
                    Thumbnail
                  </TableCell>
                ) : null}
                <TableCell style={{ color: "#fafafa" }} align="center">
                  id
                </TableCell>
                <TableCell style={{ color: "#fafafa" }} align="center">
                  From
                </TableCell>
                <TableCell style={{ color: "#fafafa" }} align="center">
                  to
                </TableCell>
                <TableCell style={{ color: "#fafafa" }} align="center">
                  Amount
                </TableCell>
                <TableCell style={{ color: "#fafafa" }} align="center">
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography style={{ textTransform: "none" }}>No information on transactions</Typography>
      )}
    </div>
  );
};
